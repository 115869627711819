import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import productBenefits from '../../../assests/Product/productBenefits.svg'
import benefits1 from '../../../assests/Product/benefits1.svg'
import benefits2 from '../../../assests/Product/benefits2.svg'
import benefits3 from '../../../assests/Product/benefits3.svg'
import benefits4 from '../../../assests/Product/benefits4.svg'
import benefits5 from '../../../assests/Product/benefits5.svg'

const Benefits = () => {
  return (
    <>
    <Container style={{paddingTop: '30px'}}>
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontSize: '24px', fontFamily: 'Circular'}}>Benefits of Using Pradocs</h1>
            <p style={{fontFamily: 'Circular-Book', fontSize: '18px'}}>Pradocs is one of the top-ranking Document management software (DMS) with immense benefits you can utilize it easily and gain impeccable results.</p>
        </div>

        <Row style={{paddingTop: '30px', paddingBottom: '20px'}}>
            <Col lg={6}>
                <img style={{width: '100%'}} src={productBenefits}/>
            </Col>

            <Col lg={6} >
                <ul style={{listStyle: 'none', lineHeight: '20px', fontFamily: 'Circular-Book', fontSize: '18px'}}>
                    
                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '20px'}}><img src={benefits1} style={{paddingRight: '20px'}}/><div>Helps manage and support document policy formation.</div></li>
                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '20px'}}><img src={benefits2} style={{paddingRight: '20px'}}/><div>Documenting files is easy and compatible.</div></li>
                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '20px'}}><img src={benefits3} style={{paddingRight: '20px'}}/><div>Helps facilitate essential documents of your organization.</div></li>
                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '20px'}}><img src={benefits4} style={{paddingRight: '20px'}}/><div>Making corrections to files amongst the team is made simple.</div></li>
                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '20px'}}><img src={benefits5} style={{paddingRight: '20px'}}/><div>Protects valuable information of the client and organization efficiently.</div></li>
                </ul>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Benefits