import React, { useEffect } from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import NavBar from '../../common/NavBar/NavBar'
import AboutOverview from './AboutOverview/AboutOverview'
import Progress from './Progress/Progress'
import Faq from './Faq/Faq'
import Platform from './Platform/Platform'
import Footer from '../../common/Footer/Footer'
import { Helmet } from "react-helmet";

const AboutPage = () => {



  useEffect(() => {
    document.title = "About"
  }, [])


  return (

    
    <>
    {/* <Helmet>
    <meta name="description" content="Welcome to Praathee, your go-to platform for all things related to technology, innovation, and more!" />
        <meta name="keywords" content="technology, innovation, Praathee, blog, resources" />
        <meta property="og:title" content="Home - Praathee" />
        <meta property="og:description" content="Explore the latest trends in technology on Praathee." />
        <meta property="og:image" content="https://www.praathee.com/images/og-image.jpg" />
        <meta property="og:url" content="https://www.praathee.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - Praathee" />
        <meta name="twitter:description" content="Explore the latest trends in technology on Praathee." />
        <meta name="twitter:image" content="https://www.praathee.com/images/og-image.jpg" />
     
    </Helmet> */}

    <NavBar/>
    <AboutBanner/>
    <AboutOverview/>
    <Progress/>
    <Faq/>
    <Platform/>
    <Footer/>
    </>
  )
}

export default AboutPage