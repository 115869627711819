import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import software1 from '../../../../assests/Services/Software_Maintenance/software1.svg'
import software2 from '../../../../assests/Services/Software_Maintenance/software2.svg'
import software3 from '../../../../assests/Services/Software_Maintenance/software3.svg'
import software4 from '../../../../assests/Services/Software_Maintenance/software4.svg'
import software5 from '../../../../assests/Services/Software_Maintenance/software5.svg'
import animeImage from '../../../../assests/Services/Cloud_Application/animeImage.svg'
import { motion } from 'framer-motion'

const Software_Services = () => {


    const webCardData = [{
                    id: 1,
                    title: 'Bug Fixing and Troubleshooting',
                    content: "Our experts don't stop with the completion of app creation or development. Instead, we work beyond your expectations. For instance, we undergo stringent tests during the maintenance phase and make your application bug-free seamlessly. Additionally, whenever you need assistance, we troubleshoot your problem and work with the same enthusiasm, providing excellent solutions quickly",
                    style: {"border": "5px solid #DFACFF"},
                    img: software1,
            
                },
            
                {
                    id: 2,
                    title: 'Regular Software Updates and Patches',
                    content: "When you are working in the software industry staying up-to-date with futuristic developments is necessary. As this is important, our team works tirelessly and builds applications based on the updations that fit the needs of advancements efficiently. So, whenever you need to update or add additional security measures to prevent cyber-attacks, you can contact our team and get results soon.",
                    style: {"border": "5px solid #90FFCB"},
                    img: software2,
            
                },
            
                {
                    id: 3,
                    title: 'Performance Optimization and Tuning',
                    content: 'As one of the most potential software solution developers, we give utmost priority to our client’s needs and safety. So, once your application is delivered, we will constantly monitor and optimize your servers to ensure optimal performance, avoid potential issues, and enhance security. Furthermore, your user interface will also be made simple accordingly.',
                    style: {"border": "5px solid #FFACC4"},
                    img: software3,
            
                },
            
                {
                    id: 4,
                    title: 'Technical Support and Assistance',
                    content: 'With our professional technical support and assistance, you can troubleshoot problems, diagnose software and hardware problems, and find solutions to technical problems instantly. Also, as our team is client-friendly and understanding, you can get answers to all your doubts in a simplified and effective manner. To get instant support, all you have to do is contact us. We are available 24/7.',
                    style: {"border": "5px solid #E4FF99"},
                    img: software4,
            
                },
            
                {
                    id: 5,
                    title: 'Software Migration and Upgrade ',
                    content: "With our top-notch software services, you can also upgrade and migrate your applications without any difficulty in a significant way. This way, you can save time and costs by getting rid of traditional manual methods. Therefore, if you want to migrate your software applications, specify your needs to our team and get your work done instantly.",
                    style: {"border": "5px solid #FFC2A0"},
                    img: software5,
            
                }]



  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
        <Container>
            <div style={{textAlign: 'center', paddingTop: '30px', paddingBottom: '20px'}}>
                <h2 style={{fontSize: '24px', fontFamily: 'Circular'}}>Curated List Of Our Software Maintenance Services</h2>
            </div>
            <p style={{fontSize: '18px', fontFamily: 'Circular-Book'}}>As premium cloud app developers, we aim to provide exceptional cloud services that meet the needs of every expert with agility. So, the types of cloud solutions that we offer are as follows.</p>
        
            <Row style={{marginTop: '50px', display: 'flex', justifyContent: 'center'}}>
            {webCardData.map((val) => (
                
                <Col key={val.id} lg={4} md={6} sm={12}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, background: 'white', height: '90%', paddingLeft: '20px', paddingRight: '20px'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <h1 style={{fontSize: '18px', fontFamily: 'Circular'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px', fontFamily: 'Outfit-Regular', paddingBottom: '20px'}}>{val.content}</p>
                    </div>
            
                </Col>
            ))}
            
        </Row>
        
        
        </Container>
    </Container>
    </>
  )
}

export default Software_Services