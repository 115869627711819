import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import overviewImage from '../../../assests/Product/pradocsOverviewImage.svg'
import tickImage from '../../../assests/Services/Web_Application/WhyUsTick.svg'

const ProductOverview = () => {
  return (
    <>
    <Container style={{marginTop: '30px'}}>
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontFamily: 'Circular', fontSize: '34px', marginBottom: '30px'}}>Pradocs - A World-Class Document Management Software</h1>
            
        </div>
        <div style={{fontFamily: 'Circular-Book', letterSpacing: '1px'}}>
        <p style={{}}>At Praathee Technologies, we are always on the lookout and stay one step ahead with building products that best meet business needs. While we have many excellent resources, our latest product Document Management Software - Prestidocs marks its significance amongst all. Why? 
            </p>
            <p style={{}}>With our cutting-edge and simplified solution, you can say goodbye to the manual documentation process and say hi to the digitization of documents enthusiastically. Whether you are a startup or a business enterprise, you can utilize Prestidocs and get a series of benefits</p>
            </div>
        <Row>
            <Col lg={6}>
            <img src={overviewImage} style={{width: '100%'}}/>
            </Col>

            <Col lg={6} style={{display: 'flex', alignItems: 'center'}}>
            <ul style={{fontSize: '18px', listStyle: 'none', fontFamily: 'Circular-Light'}}>
                <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '15px'}}/>Centralized Storage facility (all documents)</li>
                <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '15px'}}/>Easy Access</li>
                <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '15px'}}/>Effective Retrieval Option</li>
                <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '15px'}}/>Stringent Security and</li>
                <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '15px'}}/>Compliance</li>
            </ul>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default ProductOverview