import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import whyUsArrow from '../../../../assests/Services/Mobile_Application/mobileWhyUsArrow.svg'
import whyMobile from '../../../../assests/Services/Mobile_Application/whyMobileImage.svg'

const WhyMobile = () => {
  return (
    <>
    <Container style={{marginTop: '30px', marginBottom: '40px'}}>
        <div style={{textAlign: 'center', marginBottom: '40px'}}>
        <h1 style={{fontSize: '30px', fontFamily: 'Circular'}}>Why Mobile App Development Is Important?</h1>
        <p style={{fontSize: '20px', fontFamily: 'Outfit-Regular', paddingTop: '20px', paddingBottom: '10px'}}>In today's mobile-first world, developing a mobile application is essential for businesses to stay competitive and thrive. A mobile app acts as a direct bridge to your customers, making your services more accessible and engaging. Here are the top benefits of mobile app development :</p>
        </div>
        <Row>
            <Col lg={6} md={12}>
                <img src={whyMobile} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
            </Col>

            

                <Col lg={6} md={12} sm={12} xs={12} style={{display: 'flex', alignItems: 'center'}}>
                    <ul style={{ fontFamily: 'Circular-Book', fontSize: '20px', listStyle: 'none'}}>
                    <li><p style={{lineHeight: '15px', marginBottom: '5px', fontSize: '18px', fontFamily: 'Circular'}}><img src={whyUsArrow}/>Expand Customer Reach</p>
                            <p style={{marginLeft: '29px', fontSize: '18px', fontFamily: 'Circular-Book'}}>Connect with a broader audience by leveraging the widespread use of mobile devices.</p>
                        </li>
                        <li><p style={{lineHeight: '15px', marginBottom: '5px', fontSize: '18px', fontFamily: 'Circular'}}><img src={whyUsArrow}/>Enhance Engagement</p>
                            <p style={{marginLeft: '29px', fontSize: '18px', fontFamily: 'Circular-Book'}}>Foster deeper interactions with your customers through personalized and accessible app experiences.</p>
                        </li>
                        <li><p style={{lineHeight: '15px', marginBottom: '5px', fontSize: '18px', fontFamily: 'Circular'}}><img src={whyUsArrow}/>Boost Brand Awareness</p>
                            <p style={{marginLeft: '29px', fontSize: '18px', fontFamily: 'Circular-Book'}}>Strengthen your presence and visibility with a well-designed mobile app.</p>
                        </li>
                        <li><p style={{lineHeight: '15px', marginBottom: '5px', fontSize: '18px', fontFamily: 'Circular'}}><img src={whyUsArrow}/>Improve Brand Image</p>
                            <p style={{marginLeft: '29px', fontSize: '18px', fontFamily: 'Circular-Book'}}>Showcase innovation and professionalism to create a positive impression.</p>
                        </li>
                        <li><p style={{lineHeight: '15px', marginBottom: '5px', fontSize: '18px', fontFamily: 'Circular'}}><img src={whyUsArrow}/>Increase Retention</p>
                            <p style={{marginLeft: '29px', fontSize: '18px', fontFamily: 'Circular-Book'}}>Build lasting relationships with your customers by offering convenience and consistent value.</p>
                        </li>
                        
                    </ul>
                </Col>

        </Row>
    </Container>
    </>
  )
}

export default WhyMobile