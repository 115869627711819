import React from 'react'
import EnterpriseBanner from './Enterprise_Banner/EnterpriseBanner'
import NavBar from '../../../common/NavBar/NavBar'
import CompetentEnterprise from './Competent_Enterprise/Competent_Enterprise'
import Enterprise_WhyUs from './Enterprise_WhyUs/Enterprise_WhyUs'
import Footer from '../../../common/Footer/Footer'
import EnterpriseServices from './Enterprise_Services/EnterpriseServices'

const EnterpriseSoftware = () => {
  return (
    <>
    <NavBar/>
    <EnterpriseBanner/>
    <CompetentEnterprise/>
    <EnterpriseServices/>
    <Enterprise_WhyUs/>
    <Footer/>
    </>
  )
}

export default EnterpriseSoftware