import React, { useEffect } from 'react'
import NavBar from '../../common/NavBar/NavBar'
import ContactBanner from './ContactBanner/ContactBanner'
import ContactCards from './ContactCards/ContactCards'
import ContactLocation from './ContactLocation/ContactLocation'
import Footer from '../../common/Footer/Footer'
import Timer from '../Timer/Timer'

const ContactPage = () => {

  useEffect(() => {
    document.title = "Contact"
  }, [])


  return (
    <>
    <NavBar/>
    <ContactBanner/>
    <ContactCards/>
    <ContactLocation/>
    {/* <Timer/> */}
    <Footer/>
    
    </>
  )
}

export default ContactPage