import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import whyUsImage from '../../../../assests/Services/Web_Application/WhyUsImage.svg'
import whyUsContentImage from '../../../../assests/Services/Web_Application/WhyUsContentImage.svg'
import tickImage from '../../../../assests/Services/Web_Application/WhyUsTick.svg'
import './webwhyus.css'

const WebWhyUs = () => {
  return (
    <>
        <Container style={{marginBottom: '30px', paddingTop: '30px'}}>

            <div style={{textAlign: 'center', marginBottom: '30px'}}>
                <img src={whyUsImage} style={{width: '50%'}} className='web_wyhUs_image' alt='whyUs'/>
            </div>
            <Row>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <img src={whyUsContentImage} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                </Col>

                <Col lg={6} md={12} sm={12} xs={12} style={{display: 'flex', alignItems: 'center'}}>
                    <ul style={{ fontFamily: 'Circular-Book', fontSize: '20px', listStyle: 'none'}}>
                        <li><p style={{fontSize: '20px', fontFamily: 'Circular', marginBottom: '5px'}}><img src={tickImage} style={{marginRight: '6px'}}/>Expert Website Solutions: </p>
                            <p style={{marginLeft: '35px', fontSize: '20px', fontFamily: 'Circular-Book'}}>Providing tailored, innovative, and user-friendly solutions for your unique needs.</p>
                        </li>
                        <li><p style={{fontSize: '20px', fontFamily: 'Circular', marginBottom: '5px'}}><img style={{marginRight: '6px'}} src={tickImage}/>On-Time Delivery:  </p>
                            <p style={{marginLeft: '35px', fontSize: '20px', fontFamily: 'Circular-Book'}}>Dedicated to delivering projects promptly while maintaining exceptional quality.</p>
                        </li>
                        <li><p style={{fontSize: '20px', fontFamily: 'Circular', marginBottom: '5px'}}><img style={{marginRight: '6px'}} src={tickImage}/>Cost-Effective Pricing:  </p>
                            <p style={{marginLeft: '35px', fontSize: '20px', fontFamily: 'Circular-Book'}}>Delivering exceptional services within your budget.</p>
                        </li>
                        <li><p style={{fontSize: '20px', fontFamily: 'Circular', marginBottom: '5px'}}><img style={{marginRight: '6px'}} src={tickImage}/>Quality Assurance:  </p>
                            <p style={{marginLeft: '35px', fontSize: '20px', fontFamily: 'Circular-Book'}}>Ensuring 100% satisfaction with reliable and high-standard outcomes.</p>
                        </li>
                        <li><p style={{fontSize: '20px', fontFamily: 'Circular', marginBottom: '5px'}}><img style={{marginRight: '6px'}} src={tickImage}/>End-to-End Support:  </p>
                            <p style={{marginLeft: '35px', fontSize: '20px', fontFamily: 'Circular-Book'}}>Offering seamless integration, optimization, and continuous evolution for sustained growth.</p>
                        </li>
                    </ul>


                    {/* <img src={tickImage} style={{marginRight: '15px', width: '25px'}}/>
<img src={tickImage} style={{marginRight: '15px', width: '25px'}}/>
<img src={tickImage} style={{marginRight: '15px', width: '25px'}}/>
<img src={tickImage} style={{marginRight: '15px', width: '25px'}}/>
<img src={tickImage} style={{marginRight: '15px', width: '25px'}}/>
<img src={tickImage} style={{marginRight: '15px', width: '25px'}}/> */}
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default WebWhyUs