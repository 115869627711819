import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import webDevelopment_image from '../../../assests/Home/whatWeDo/home_whatwedo_webdevelopment_image.svg'
import androidDevelopment_image from '../../../assests/Home/whatWeDo/home_whatwedo_appdevelopment_image.svg'
import cloudServices_image from '../../../assests/Home/whatWeDo/home_whatwedo_cloudservices_image.svg'
import digitalMarketing_image from '../../../assests/Home/whatWeDo/home_whatwedo_digitalmarketing_image.svg'
import uiux_image from '../../../assests/Home/whatWeDo/home_whatwedo_uiux_image.svg'
import blockchain_image from '../../../assests/Home/whatWeDo/home_whatwedo_blockchain_image.svg'

const WhatWeDo = () => {


    const cardData = [
        {
            id: 1,
            title: 'Web Development',
            description: 'Robust, progressive, and hybrid mobile app solutions that best fit for your need',
            img: webDevelopment_image,
            route: '/web-application-service',
        },
        {
            id: 2,
            title: 'App Development',
            description: 'Build a well-constructed website and web application functionality and interactivity',
            img: androidDevelopment_image,
            route: '/mobile-application-service',
        },
        {
            id: 3,
            title: 'Cloud Services',
            description: 'Exceptional real-time solutions & customized cloud solutions to your specific requirements',
            img: cloudServices_image,
            route: '/cloud-application-service',
        },
        {
            id: 4,
            title: 'Digital Marketing',
            description: 'Boosting growth with data-driven strategies for maximum engagement and results.',
            img: digitalMarketing_image,
            route: '/',
        },
        {
            id: 5,
            title: 'UX/UI Designing',
            description: 'Creating seamless, user-friendly designs that blend innovation with functionality.',
            img: uiux_image,
            route: '/',
        },
        {
            id: 6,
            title: 'Block chain',
            description: 'Unlocking possibilities with blockchain solutions: Your path to innovation and security',
            img: blockchain_image,
            route: '/blockchain-service',
        }
    ]


  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
    <Container style={{paddingBottom: '30px', paddingTop: '60px'}}>
        <div style={{textAlign: 'center'}}>
        <h2 style={{fontSize: '36px', fontWeight: 500, marginBottom: '20px', fontFamily: 'Circular'}}>What we do?</h2>
        <p style={{fontWeight: 450, fontSize: '20px', marginBottom: '50px', fontFamily: 'Circular-Book'}}>We Provide Best IT Solutions For 7+ Years</p>
        </div>
        <Row>
            {cardData.map((data) => (
                <Col lg={4} md={4} sm={3} style={{display: 'flex', justifyContent: 'center', marginBottom: '30px'}}>
                <div key={data.id} style={{textAlign: 'center'}}>
                <Card style={{borderRadius: '0', width: '100%', height: '100%', paddingTop: '28px'}}>
                    <center>
      <Card.Img style={{width: '50px', marginBottom: '10px'}} variant="top" src={data.img} />
      </center>
      <Card.Body>
        <Card.Title as="h3" style={{marginBottom: '20px', fontFamily: 'Circular', fontSize: '20px'}}>{data.title}</Card.Title>
        <Card.Text style={{marginBottom: '36px', fontFamily: 'Outfit-Regular', fontSize: '20px'}}>
                {data.description}
        </Card.Text>
        <a href={data.route}>
        <Button style={{marginBottom: '28px', borderRadius: '0', color: 'black', background: '#E7E7E7', borderColor: '#E7E7E7', fontFamily: 'Circular'}} >Know more</Button>
        </a>
      </Card.Body>
    </Card>
                    
                </div>
                </Col>
            ))}
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default WhatWeDo