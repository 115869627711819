import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import enterprise1 from '../../../../assests/Services/Enterprise_Software/enterprise1.svg'
import enterprise2 from '../../../../assests/Services/Enterprise_Software/enterprise2.svg'
import enterprise3 from '../../../../assests/Services/Enterprise_Software/enterprise3.svg'
import animeImage from '../../../../assests/Services/Cloud_Application/animeImage.svg'
import { motion } from 'framer-motion'

const EnterpriseServices = () => {


    const webCardData = [{
                    id: 1,
                    title: 'Enterprise Resource Planning (ERP) Software',
                    content: "With our experts' tailor-made ERP software solution, you can seamlessly manage core business aspects such as accounting, finance, supply chain management, and manufacturing. This comprehensive solution is offered at cost-effective prices while maintaining proven quality services.",
                    style: {"border": "5px solid #DFACFF"},
                    img: enterprise1,
            
                },
            
                {
                    id: 2,
                    title: 'Customer Relationship Management (CRM) Software',
                    content: "Customers are the driving force behind your business's success. With our efficient CRM software solution, you can consistently focus on key functions such as sales force automation, marketing automation, customer service, and lead management to elevate your business to the next level.",
                    style: {"border": "5px solid #90FFCB"},
                    img: enterprise2,
            
                },
            
                {
                    id: 3,
                    title: 'Human Resource Management (HRM) Software',
                    content: "With our well-researched and developed HRM software, you can seamlessly integrate various aspects of your company's human resources, including onboarding employees, payroll processing, talent acquisition, and performance management.",
                    style: {"border": "5px solid #FFACC4"},
                    img: enterprise3,
            
                }]



  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
        <Container>
            <div style={{textAlign: 'center', paddingTop: '30px', paddingBottom: '20px'}}>
            <h2 style={{fontSize: '24px', fontFamily: 'Circular'}}>The Versatile Enterprise Software Solutions We Offer</h2>
            </div>
            <p style={{fontSize: '18px', fontFamily: 'Circular-Book', paddingBottom: '30px'}}>As we under the potential of these applications, our experts have designed various custom-made enterprise applications and or looking forward to upgrading more. So, whatever your needs are, you can choose and specify it accordingly for the best results</p>
        
        
            <Row style={{marginTop: '50px', display: 'flex', justifyContent: 'center'}}>
            {webCardData.map((val) => (
                
                <Col key={val.id} lg={4} md={6} sm={12}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, background: 'white', height: '90%', paddingLeft: '20px', paddingRight: '20px'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <h1 style={{fontSize: '18px', fontFamily: 'Circular'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px', fontFamily: 'Outfit-Regular', paddingBottom: '20px'}}>{val.content}</p>
                    </div>
            
                </Col>
            ))}
            
        </Row>
        
        
        
        
        
        </Container>
    </Container>
    </>
  )
}

export default EnterpriseServices