import React from 'react'
import CloudBanner from './Cloud_Banner/CloudBanner'
import NavBar from '../../../common/NavBar/NavBar'
import Ultimate_Banner from './Ultimate_Platform/Ultimate_Banner'
import CloudServices from './Cloud_App_Services/CloudServices'
import Footer from '../../../common/Footer/Footer'

const Cloud_App_Page = () => {
  return (
    <>
    <NavBar/>
    <CloudBanner/>
    <Ultimate_Banner/>
    <CloudServices/>
    <Footer/>
    </>
  )
}

export default Cloud_App_Page