import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import faqImage from '../../../assests/About/Faq.svg'
import './Faq.css'

const Faq = () => {
  return (
    <>
    <Container style={{marginBottom: '60px'}}>
        <div style={{textAlign: 'center', marginBottom: '22px'}}><img src={faqImage} alt="" /></div>
        <div>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{fontWeight: 450, fontSize: '24px', fontFamily: 'Circular-Book'}}>How best does your company help improve our IT infrastructure?</Accordion.Header>
        <Accordion.Body style={{fontFamily: 'Circular-Light'}}>
        As a leading software development company, we provide specialized services in all areas of expertise. From optimizing better performance to delivering products in the said time, everything is prioritized. Besides that, our service focuses on every field. I.e., from startups to large enterprises, we provide expert solutions with excellence and professionalism. So, undoubtedly you can choose from our services and succeed.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{fontFamily: 'Circular-Book'}}>List the security measures that you provide to your clients?</Accordion.Header>
        <Accordion.Body style={{fontFamily: 'Circular-Light'}}>
        We give high priority to our client's security. As every detail is crucial, we follow a strict privacy policy through this list of methods
        <ul>
          <li>Advanced encrypted methods</li>
          <li>Robust firewall protection</li>
          <li>Extensive security audits</li>
          <li>Comprehensive employee training</li>
          <li>Multi-layered approach</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header style={{fontFamily: 'Circular-Book'}}>How do you calculate your pricing structure for design and development services?</Accordion.Header>
        <Accordion.Body style={{fontFamily: 'Circular-Light'}}>
        Our pricing structure varies with the design and services that you choose. While the pricing structure might differ they are cost-friendly and packed within your budget. Besides that, here you don't need to pay anything extra. Pricing standards are simple and of the range.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </div>
    </Container>
    </>
  )
}

export default Faq