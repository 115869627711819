import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import contact_banner from '../../../assests/Contact/contact_banner.svg'

const ContactBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)', marginBottom: '40px'}}>
    <Container>
        <Row>
            <Col lg={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <h1 style={{fontSize: '46px', fontWeight: 500, marginBottom: '29px', fontFamily: 'Circular'}}>Contact</h1>
                <p style={{fontSize: '22px', fontWeight: 400, lineHeight: '46px', fontFamily: 'Outfit-Regular'}}>You’re just one step away from elevating your business to new heights. Our expert team is ready to provide tailored solutions and seamless support to meet your unique needs. Let us help you unlock your business’s full potential and achieve your goals with confidence.</p>
            </Col>

            <Col lg={6}>
                <img style={{width: '100%'}} src={contact_banner} alt="" />
            </Col>
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default ContactBanner