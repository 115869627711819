import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import featureImage1 from '../../../assests/Product/productFeatureImage1.svg'
import featureImage2 from '../../../assests/Product/productFeatureImage2.svg'
import featureImage3 from '../../../assests/Product/productFeatureImage3.svg'

import featureImage4 from '../../../assests/Product/productFeatureImage4.svg'
import featureImage5 from '../../../assests/Product/productFeatureImage5.svg'
import axios from 'axios'


const Features = () => {

  

    const text = `In today's digital age, choosing the right solution for your business is essential. With Prestidocs, you gain access to powerful,
     feature-rich tools designed to meet your needs. Explore its highlights and see how it can inspire and transform your business!`


     const featureList = [{

        id: 1,
        title: "Robust Storage and Streamlined Processes",
        desc: "The key to effective document management software is finding a solution that perfectly aligns with your business needs. With our DMS, you can effortlessly manage and store large volumes of files with ample storage capacity. Additionally, its streamlined processes save you valuable time, allowing you to focus on more critical tasks.",
        style: {"borderTop": "5px solid #0E3390"},
        img: featureImage1,
     },
    
    {
        id: 2,
        title: "Impressive Tracking Options",
        desc: "With Pradocs' easy-to-use interface, you don't have to spend a lot of time, searching. Instead, with the automated tracking facility, you can find the files that you need quickly and easily. So, utilize our DMS and get access to your files instantly.",
        style: {"borderTop": "5px solid #0E3390"},
        img: featureImage2,
    },

    {
        id: 3,
        title: "Workflow Capabilities and Enhanced Collaboration",
        desc: "When editing or retrieving files, enhanced collaboration opportunities that let you do your work precisely are a must. With Pradocs, you can get advanced facilities such as real-time access, version control ability, and privacy-protected sharing capabilities exclusively. Thus, this way, you can collaborate with your teammates and get work done smartly.",
        style: {"borderTop": "5px solid #0E3390", "width": "140px"},
        img: featureImage3,
        
    },

    {
        id: 4,
        title: "Privacy and Protection",
        desc: "While managing documents with Pradocs, you don't have to worry about the safety of the files at all. With our efficient software, you can have access to control, audit trails, and encryption. This is vital because these features ensure protection against unauthorized access, reducing the risk of data piracy simultaneously.",
        style: {"borderTop": "5px solid #0E3390"},
        img: featureImage4,
    },

    {
        id: 5,
        title: "Limits Budget and Improves Productivity",
        desc: "Whether you're a startup or a large enterprise, our user-friendly document management software, helps reduce costs and boost productivity. With advanced search capabilities, quick retrieval, and efficient indexing, it streamlines your work for faster results. Choose Pradocs today and achieve success with budget-friendly, powerful tools.",
        style: {"borderTop": "5px solid #0E3390"},
        img: featureImage5,
    }]

  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
    <Container style={{marginTop: '20px'}}>
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontSize: '34px', fontFamily: 'Circular', paddingBottom: '24px', paddingTop: '40px'}}>The Key Features Of Our Digital Workspace - Pradocs</h1>
            <p style={{fontSize: '18px', fontFamily: 'Circular-Book', paddingBottom: '50px'}}>{text}</p>
        </div>

        <Row style={{display: 'flex', justifyContent: 'center', rowGap: '30px', paddingBottom: '40px'}}>
            {featureList.map((val) => (
                <Col lg={4} md={6} sm={12} xs={12} key={val.id} >
                    <div style={{ borderTop:val?.style?.borderTop, borderBottom:val?.style?.borderBottom, background: 'white', height: '100%', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'}}>
                        <div style={{textAlign: 'center', marginBottom: '40px', marginTop: '60px'}}>
                        <img style={{width: val?.style?.width}} src={val.img}/>
                        </div>
                        <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
                        <h1 style={{fontSize: '16px', fontFamily: 'Circular'}}>{val.title}</h1>
                        <hr/>
                        <p style={{fontSize: '14px', fontFamily: 'Outfit-Regular'}}>{val.desc}</p>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>

            

            
    </Container>
    </Container>
    </>
  )
}

export default Features