import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import cloudBanner from '../../../../assests/Services/Cloud_Application/cloudBanner.svg'
import './CloudBanner.css'

const CloudBanner = () => {
  return (
    <>
    <Container fluid style={{background: "linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)"}}>
        <Container>
            <Row>
                <Col lg={6} md={6} className='cloudBanner'>
                    <img src={cloudBanner} style={{width: '90%'}}/> 
                </Col>

                <Col lg={6} md={12} sm={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <h1 style={{fontFamily: 'Circular', fontSize: '28px', paddingBottom: '20px'}}>Cloud App Development</h1>
                    <p style={{fontFamily: 'Outfit-Regular', fontSize: '20px', lineHeight: '40px'}}>If you are a startup or software development firm that needs efficient “cloud” solutions, get in touch with the best software development service provider Praathee Technologies. With their potent service and professionals, build excellent cloud applications that help, accelerate changes in your business through digital transformation seamlessly.</p>
                </Col>
            </Row>
        </Container>
    </Container>
    </>
  )
}

export default CloudBanner