import React from 'react'
import { Container } from 'react-bootstrap'

import './OurTrustedClients.css'




import ansLogo from '../../../assests/Home/ourClients/ANS_logo.svg'
import yourFarmLogo from '../../../assests/Home/ourClients/YOUR_FARM_logo.svg'
import relianceLogo from '../../../assests/Home/ourClients/RELIANCE_logo.svg'
import netCreativeLogo from '../../../assests/Home/ourClients/NET_CREATIVE_logo.svg'
import eonianLogo from '../../../assests/Home/ourClients/EONIAN_logo.svg'

const OurTrustedClients = () => {

  


  return (
    <>
    <Container fluid style={{backgroundColor: '#EDEDFD'}}>
        <div style={{textAlign: 'center', backgroundColor: '#EDEDFD', paddingTop: '50px'}}>
        <h2 style={{fontWeight: 500, fontSize: '36px', fontFamily: 'Circular'}}>Our trusted clients</h2>
        </div>

        <div style={{backgroundColor: '#EDEDFD', paddingBottom: '10px'}}>
            <div className="logos">
              <div className="logos-slide">
                <img style={{width: '100%'}} src={ansLogo} alt="" />
                <img style={{width: '100%'}} src={yourFarmLogo} alt="" />
                <img style={{width: '100%'}} src={relianceLogo} alt="" />
                <img style={{width: '100%'}} src={netCreativeLogo} alt="" />
                <img style={{width: '100%'}} src={eonianLogo} alt="" />
                
              </div>

              
            </div>
        </div>

    </Container>
    </>
  )
}

export default OurTrustedClients