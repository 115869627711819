import React, { useState } from 'react'
import { Button, Col, Container, Nav, Navbar, NavDropdown, NavLink, Offcanvas, Row, Tab } from 'react-bootstrap'
import praathee_logo from '../../assests/NavBar/praatheeColorLogo.svg'
import './NavBar.css'
import { Link, useLocation } from 'react-router-dom'
import webDev from '../../assests/NavBar/navbarWebDevelopment.svg'
import appDev from '../../assests/NavBar/navbarAppDevelopment.svg'
import cloudDev from '../../assests/NavBar/navbarCloudDevelopment.svg'
import blockDev from '../../assests/NavBar/blockchainDev.svg'

const NavBar = () => {

  const [show, setShow] = useState(false)

  const handleMouseEnter = () => setShow(true)

  const handleMouseLeave = () => setShow(false)

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  const location = useLocation()

  const commonStyle = {
    paddingTop: '28px',
    paddingBottom: '21px'
  }

  const dynamicColor = {
    background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)' 
  }

  

  return (
    <Container fluid style={{...commonStyle, ...dynamicColor, position: 'sticky', top: '0', zIndex: 1020}}>
      
    
    <Navbar expand="lg" className='sticky-top'>
      <Container  className='navConatiner' style={{display: 'flex'}}>
        <Navbar.Brand as={Link} to='/'><img style={{width: '100%'}} src={praathee_logo} alt="" /></Navbar.Brand>
        
        
        <Navbar.Collapse style={{flexGrow: '0', background: 'white', borderRadius: '50px'}} id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to='/' active={location.pathname === "/"} >Home</Nav.Link>
            <Nav.Link as={Link} to='/about' active={location.pathname === "/about"} >About</Nav.Link>
            {/* <Nav.Link as={Link} to='/'>Portfolio</Nav.Link> */}
            <NavDropdown active={location.pathname.includes('service')} title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to='/web-application-service'><img style={{marginRight: '10px'}} src={webDev}/>Web App Development</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to='/mobile-application-service'>
              <img style={{marginRight: '10px'}} src={appDev}/>
                Mobile App Development
              </NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item as={Link} to='/cloud-application-service'>
              <img style={{marginRight: '10px'}} src={cloudDev}/>
                Cloud Services
              </NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item as={Link} to='/blockchain-service'>
              <img style={{marginRight: '10px'}} src={blockDev}/>
                Block chain
              </NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item as={Link} to='/It-Staffing-service'>
              {/* <img style={{marginRight: '10px'}} src={blockDev}/> */}
                IT Staffing
              </NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item as={Link} to='/enterprise-software-service'>
              {/* <img style={{marginRight: '10px'}} src={blockDev}/> */}
                Enterprise Software
              </NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item as={Link} to='/software-maintenance-service'>
              {/* <img style={{marginRight: '10px'}} src={blockDev}/> */}
                Software Maintenance
              </NavDropdown.Item>

             
              
            </NavDropdown>
            <Nav.Link as={Link} to='/product' active={location.pathname === '/product'}>Product</Nav.Link>
            <Nav.Link as={Link} to='/career' active={location.pathname === '/career'}>Career</Nav.Link>
            <Nav.Link as={Link} to='/contact' active={location.pathname === '/contact'}>Contact</Nav.Link>
          </Nav>

        </Navbar.Collapse>
        <div style={{display: 'flex', gap: '15px'}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
        <Button style={{color: 'black', background: 'none', border: '1px solid #2B2B2B', width: '130px', fontFamily: 'Circular'}} className='rounded-pill get-button' ><Link to='/contact' style={{textDecoration: 'none', color: '#000000'}}>Get in touch</Link></Button>
        </div>
      </Container>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><img src={praathee_logo} style={{width: '100%'}}/></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link as={Link} to='/' active={location.pathname === "/"} onClick={handleClose}>Home</Nav.Link>
            <Nav.Link as={Link} to='/about' active={location.pathname === "/about"} onClick={handleClose}>About</Nav.Link>
            {/* <Nav.Link as={Link} to='/portfolio' onClick={handleClose}>Portfolio</Nav.Link> */}
            <NavDropdown title="Services" id="offcanvas-nav-dropdown">
              <NavDropdown.Item as={Link} to='/web-application-service' onClick={handleClose}>
                <img style={{ marginRight: '10px' }} src={webDev} alt="Web Dev" />
                Web App Development
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to='/mobile-application-service' onClick={handleClose}>
                <img style={{ marginRight: '10px' }} src={appDev} alt="App Dev" />
                Mobile App Development
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to='/cloud-application-service' onClick={handleClose}>
                <img style={{ marginRight: '10px' }} src={cloudDev} alt="App Dev" />
                Cloud Services
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to='/blockchain-service' onClick={handleClose}>
                <img style={{ marginRight: '10px' }} src={blockDev} alt="App Dev" />
                Block chain
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to='/product' active={location.pathname === '/product'} onClick={handleClose}>Product</Nav.Link>
            <Nav.Link as={Link} to='/career' active={location.pathname === '/career'} onClick={handleClose}>Career</Nav.Link>
            <Nav.Link as={Link} to='/contact' active={location.pathname === '/contact'} onClick={handleClose}>Contact</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
    </Container>

//     <Container fluid style={{ background: '#E9F0FF', paddingTop: '28px', paddingBottom: '21px' }}>
//   <Row>
//     <Col>
//       <Navbar expand="lg">
//         <Container>
//           <Navbar.Brand href="#home">
//             <img src={praathee_logo} alt="Logo" />
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse style={{ background: 'white', borderRadius: '50px' }} id="basic-navbar-nav">
//             <Nav className="me-auto">
//               <Nav.Link href="#home">Home</Nav.Link>
//               <Nav.Link href="#link">About</Nav.Link>
//               <Nav.Link href="#home">Portfolio</Nav.Link>
//               <NavDropdown title="Services" id="basic-nav-dropdown">
//                 <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//                 <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
//                 <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
//               </NavDropdown>
//               <Nav.Link href="#home">Product</Nav.Link>
//               <Nav.Link href="#home">Career</Nav.Link>
//               <Nav.Link href="#home">Contact</Nav.Link>
//             </Nav>
//           </Navbar.Collapse>
//           <Button className="rounded-pill" style={{ color: 'black', background: 'none', border: '1px solid #2B2B2B' }}>Get in touch</Button>
//         </Container>
//       </Navbar>
//     </Col>
//   </Row>
// </Container>

  )
}

export default NavBar