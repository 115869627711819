import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import competitiveStaffing from '../../../../assests/Services/IT_Staffing/competitiveStaffing.svg'
import tickImage from '../../../../assests/Services/IT_Staffing/tickImage.svg'

const CompetitiveStaffing = () => {
  return (
    <>
    <Container>
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontSize: '26px', fontFamily: 'Circular', paddingBottom: '20px', paddingTop: '30px'}}>Praathee Technologies - A Competitive Staffing Service Company</h1>
        </div>
        <p style={{fontSize: '20px', fontFamily: 'Circular-Book'}}>As a leading software solution company, Praathee is the best in providing versatile services. With years of experience, the team thoroughly understands its clients' needs. So, to make IT staffing solutions easy and effective, we help find the perfect candidate for your company. For instance, with us, you can hire the ideal candidates according to your preferences. Furthermore, you get the candidates on this basis too.</p>
    
      <Row>
        <Col lg={6}>
          <img src={competitiveStaffing} style={{width: '100%'}}/>
        </Col>

        <Col lg={6} style={{display: 'flex', alignItems: 'center'}}>
        <ul style={{listStyle: 'none', fontFamily: 'Circular-Book', fontSize: '18px'}}>
          <li style={{paddingBottom: '30px', display: 'flex'}}><img src={tickImage} style={{marginRight: '10px'}}/>Contract or Project Based</li>
          <li style={{paddingBottom: '30px', display: 'flex'}}><img src={tickImage} style={{marginRight: '10px'}}/>Direct/Permanent Hire</li>
          <li style={{paddingBottom: '30px', display: 'flex'}}><img src={tickImage} style={{marginRight: '10px'}}/>Contract-to-Hire and</li>
          <li style={{paddingBottom: '30px', display: 'flex'}}><img src={tickImage} style={{marginRight: '10px'}}/>Managed Staffing Services</li>
        </ul>
        </Col>
      </Row>
    
    </Container>
    </>
  )
}

export default CompetitiveStaffing