import React from 'react'
import NavBar from '../../../common/NavBar/NavBar'
import Software_Banner from './Software_Banner/Software_Banner'
import Software_Services from './Software_Services/Software_Services'
import Footer from '../../../common/Footer/Footer'

const Software_Maintenace = () => {
  return (
    <>
    <NavBar/>
    <Software_Banner/>
    <Software_Services/>
    <Footer/>
    </>
  )
}

export default Software_Maintenace