import React, { useEffect } from 'react'
import MobileBanner from './Mobile_Banner/MobileBanner'
import MobileServices from './Mobile_App_Services/MobileServices'
import NavBar from '../../../common/NavBar/NavBar'
import WhyMobile from './Why_Mobile/WhyMobile'
import Footer from '../../../common/Footer/Footer'
import MobileDevelopmentServices from './Mobile_Development_Services/MobileDevelopmentServices'
import AndroidServices from './Android_App_Services/AndroidServices'
import IosServices from './Ios_App_Services/IosServices'
import Our_Mobile_dev from './Our_Mobile_Dev/Our_Mobile_dev'

const Mobile_App_Page = () => {


  useEffect(() => {
    document.title = "Mobile App Development"
  }, [])

  return (
    <>
    <NavBar/>
    <MobileBanner/>
    {/* <Our_Mobile_dev/> */}
    {/* <MobileDevelopmentServices/> */}
    <AndroidServices/>
    <IosServices/>
    <MobileServices/>
    <WhyMobile/>
    <Footer/>
    </>
  )
}

export default Mobile_App_Page