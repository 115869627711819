import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import tickImage from '../../../../assests/Services/Web_Application/WhyUsTick.svg'
import ultimateImage from '../../../../assests/Services/Cloud_Application/ultimateImage.svg'
import './ultimate.css'

const Ultimate_Banner = () => {

  const listData = [{
    id: 1,
    data: 'Quick Responses',
    img: tickImage,
  },

  {
    id: 2,
    data: 'Easy Access and Scalability',
    img: tickImage,
  },

  {
    id: 3,
    data: 'Budget-friendly Prices',
    img: tickImage,
  },

  {
    id: 4,
    data: 'Automatic Updations',
    img: tickImage,
  },

  {
    id: 5,
    data: 'Top-Notch Maintenance',
    img: tickImage,
  },]


  return (
    <>
    <Container>
        <div style={{textAlign: 'center'}}>
        <h1 style={{fontSize: '28px', fontFamily: 'Circular', paddingBottom: '20px', paddingTop: '30px'}}>Praathee Technologies - You Ultimate Platform to Avail Endless Digital Possibilities</h1>
        </div>
        <p style={{fontSize: '20px', fontFamily: 'Circular-Book'}}>At Praathee, We are well-versed in app developments that meet digital transformation needs accordingly. With the digitization upsurge, the need for agile and simple solutions like “Cloud app development” is efficient. While developing cloud solutions for app development is challenging with the right team the benefits you reap are tremendous. So, when you get your services from us, the benefits that you avail are,</p>
    
        <Row style={{rowGap: '40px'}}>
            <Col lg={6} md={6} sm={12} className='ultimateColumnImage' style={{display: 'flex', justifyContent: 'end'}}>
              <img src={ultimateImage} style={{width: '80%'}}/>
            </Col>

            <Col lg={6} md={6} sm={12} style={{display: 'flex', alignItems: 'end'}}>
              <ul style={{listStyle: 'none'}}>
                {listData.map((val) => (
                  <div key={val.id}>
                    <li style={{paddingBottom: '33px', display: 'flex'}}><img src={val.img} style={{paddingRight: '10px'}}/>{val.data}</li>
                  </div>
                ))}

              </ul>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Ultimate_Banner