import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import kickStart2 from '../../../assests/Career/kickstart2.svg'
import './kickstart2.css'

const Kickstart2 = () => {
  return (
    <>
    <Container fluid style={{background: '#E9F0FF', marginBottom: '40px'}}>
        <Container>

            <Row>
                <Col lg={4} md={6} className='kickstart2_banner' style={{display: 'flex', alignItems: 'center'}}>
                    <img src={kickStart2} style={{width: '100%'}}/>
                </Col>

                <Col lg={8} md={12} style={{display: 'flex', alignItems: 'center'}}>
                    <ul style={{fontFamily:'Outfit-Regular', fontSize: '22px', paddingTop: '40px', paddingBottom: '40px'}}>
                        <li style={{lineHeight: '40px'}}>With Praathee, get ready to discover and explore exciting career opportunities that await you. As a leading software development company, we offer a variety of roles such as, Software engineering, Product management, Data science, UX/UI design, and more.</li>
                        <li style={{lineHeight: '40px'}}>Furthermore, if your passion lies in coding elegant solutions, crafting intuitive user experiences, or analyzing data to drive business decisions, you can find the best fit over here and succeed exclusively.</li>
                    </ul>
                </Col>
            </Row>

            
        </Container>
        </Container>
    </>
  )
}

export default Kickstart2