import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import tickImage from '../../../../assests/Services/IT_Staffing/tickImage.svg'
import competentEnterprise from '../../../../assests/Services/Enterprise_Software/competentEnterprise.svg'

const CompetentEnterprise = () => {
  return (
    <>
    <Container>
      <div style={{textAlign: 'center', paddingTop: '30px', paddingBottom: '15px'}}>
        <h2 style={{fontSize: '26px', fontFamily: 'Circular'}}>Praathee Technologies - Competent Enterprise Software Development Solutions</h2>
      </div>
      <p style={{fontSize: '20px', fontFamily: 'Circular-Book'}}>At Praathee, We create and develop robust Enterprise solutions that meet every business needs perfectly. With the advancement of digitization in business, adapting and utilizing new technologies like ERP, CRM, Accounting, and Supply chain logistics can do wonders. For instance, when you add these software applications, you can,</p>
    
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img src={competentEnterprise} style={{width: '90%'}}/>
        </Col>

        <Col lg={6} md={6} sm={12} xs={12} style={{display: 'flex', alignItems: 'center'}}>
          <ul style={{listStyle: 'none'}}>
            <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '10px'}}/>Enhance Customer Experience</li>
            <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '10px'}}/>Leverage Real-Time Insights & Analytics</li>
            <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '10px'}}/>Automate Enterprise Workflow</li>
            <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '10px'}}/>Boost Collaboration</li>
            <li style={{display: 'flex', paddingBottom: '30px'}}><img src={tickImage} style={{marginRight: '10px'}}/>Elevate Revenue and Profits</li>
          </ul>
        </Col>
      </Row>
    
    </Container>
    </>
  )
}

export default CompetentEnterprise