// import React from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
// import animeImage from '../../../../assests/Services/Cloud_Application/animeImage.svg'
// import saasImage from '../../../../assests/Services/Cloud_Application/saasImage.svg'
// import paasImage from '../../../../assests/Services/Cloud_Application/paasImage.svg'
// import iaasImage from '../../../../assests/Services/Cloud_Application/iaasImage.svg'
// import customizationImage from '../../../../assests/Services/Cloud_Application/customizationImage.svg'
// import reliabiltyImage from '../../../../assests/Services/Cloud_Application/reliabilityImage.svg'
// import experienceImage from '../../../../assests/Services/Cloud_Application/experienceImage.svg'

// const CloudServices = () => {

//     const cardData = [{
//         id: 1,
//         title: 'SaaS',
//         content: 'Leveraging our SaaS services, you can create entirely new or retain your existing software efficiently without any hassle. This can be utterly beneficial because our experts use advanced cloud technology like AI/ML, AR/VR, and blockchain. So, this way, your SaaS management for all SaaS implementations will be scalable and come packed with an effective user interface.',
//         img: saasImage,
//         animeImage: animeImage,
//     },

//     {
//         id: 2,
//         title: 'PaaS',
//         content: 'PaaS, often referred to by business enterprises as open-source projects, is one of the most used cloud solutions that help service providers build, develop, and update applications securely and effectively. As we understand the importance of PaaS, we offer tailored solutions for custom platforms and drive team development significantly.',
//         img: paasImage,
//         animeImage: animeImage,
//     },
    
  
//     {
//         id: 3,
//         title: 'IaaS',
//         content: 'With the best-developed IaaS cloud-computing service, eliminate the responsibility of managing and maintaining infrastructure. Use this advanced and simplified solution thus paving the way for aiding infrastructure services such as managing bills, logging, storage resiliency, and monitoring to boost compliance and security.',
//         img: iaasImage,
//         animeImage: animeImage,
//     },

//     {
//         id: 4,
//         title: 'Customization',
//         content: "We work with great agility and are determined to create or develop cloud-based applications according to the client's preferences. Besides that, we are also equipped with first-class technology and an expert team that understands your cloud needs exclusively. So, unleash your imagination and get accelerated cloud-native applications methodically.",
//         img: customizationImage,
//         animeImage: animeImage,
//     },

//     {
//         id: 5,
//         title: 'Reliability',
//         content: 'We understand your needs and create/develop cloud software solutions with hi-tech resources with our team of experts exponentially. Also, we deliver the applications on time with prices that are budget-friendly. So, with us, you get 100% guaranteed results with precision and stability.',
//         img: reliabiltyImage,
//         animeImage: animeImage,
//     },

//     {
//         id: 6,
//         title: 'Experience',
//         content: 'Over the years, our team of skilled software developers has created and retained many custom based cloud applications that are best in the platform. Also, they stand the test of time with excellent results. Hence, uplift your expertise with our cloud app solutions and shine.',
//         img: experienceImage,
//         animeImage: animeImage,
//     },]


//   return (
//     <>
//     <Container fluid style={{background: "rgb(233, 240, 255)"}} >
//         <Container style={{paddingTop: '40px'}}>
//             <div style={{textAlign: 'center', paddingBottom: '14px'}}>
//                 <h1 style={{fontSize: '24px', fontFamily: 'Circular'}}>The Power of Praathee's Distinguished Cloud App Services</h1>
//             </div>
//             <p style={{fontSize: '18px', fontFamily: 'Circular-Book'}}>As premium cloud app developers, we aim to provide exceptional cloud services that meet the needs of every expert with agility. So, the types of cloud solutions that we offer are as follows.</p>
            
//             <Row>
//               {cardData.map((val) => (
//                 <Col key={val.id} lg={4}>
//                   <div style={{background: 'white', padding: '0px 20px 20px 20px'}}>
//                     <div style={{ position: 'relative', marginBottom: '40px'}}>
//                       <div style={{textAlign: 'center'}}>
//                     <img src={val.img}/>
//                     <img style={{paddingBottom: '40px', paddingTop: '40px', position: 'absolute'}} src={val.animeImage}/>
//                     </div>
//                     </div>
                    
//                     <h2 style={{paddingBottom: '10px', fontSize: '18px', fontFamily: 'Circular'}}>{val.title}</h2>
//                     <p style={{fontSize: '15px', fontFamily: 'Outfit-Regular'}}>{val.content}</p>
//                   </div>
//                 </Col>
//               ))}
//             </Row>
//         </Container>
//     </Container>
//     </>
//   )
// }

// export default CloudServices









import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import saasImage from '../../../../assests/Services/Cloud_Application/saasImage.svg'
import paasImage from '../../../../assests/Services/Cloud_Application/paasImage.svg'
import iaasImage from '../../../../assests/Services/Cloud_Application/iaasImage.svg'
import customizationImage from '../../../../assests/Services/Cloud_Application/customizationImage.svg'
import reliabiltyImage from '../../../../assests/Services/Cloud_Application/reliabilityImage.svg'
import experienceImage from '../../../../assests/Services/Cloud_Application/experienceImage.svg'
import animeImage from '../../../../assests/Services/Cloud_Application/animeImage.svg'
import { motion } from 'framer-motion'

const CloudServices = () => {


    const webCardData = [{
        id: 1,
        title: 'SaaS',
        content: 'Leveraging our SaaS services, you can create entirely new or retain your existing software efficiently without any hassle. This can be utterly beneficial because our experts use advanced cloud technology like AI/ML, AR/VR, and blockchain. So, this way, your SaaS management for all SaaS implementations will be scalable and come packed with an effective user interface.',
        style: {"border": "5px solid #DFACFF"},
        img: saasImage,

    },

    {
        id: 2,
        title: 'PaaS',
        content: 'PaaS, often referred to by business enterprises as open-source projects, is one of the most used cloud solutions that help service providers build, develop, and update applications securely and effectively. As we understand the importance of PaaS, we offer tailored solutions for custom platforms and drive team development significantly.',
        style: {"border": "5px solid #90FFCB"},
        img: paasImage,

    },

    {
        id: 3,
        title: 'IaaS',
        content: 'With the best-developed IaaS cloud-computing service, eliminate the responsibility of managing and maintaining infrastructure. Use this advanced and simplified solution thus paving the way for aiding infrastructure services such as managing bills, logging, storage resiliency, and monitoring to boost compliance and security.',
        style: {"border": "5px solid #FFACC4"},
        img: iaasImage,

    },

    {
        id: 4,
        title: 'Customization',
        content: "We work with great agility and are determined to create or develop cloud-based applications according to the client's preferences. Besides that, we are also equipped with first-class technology and an expert team that understands your cloud needs exclusively. So, unleash your imagination and get accelerated cloud-native applications methodically.",
        style: {"border": "5px solid #E4FF99"},
        img: customizationImage,

    },

    {
        id: 5,
        title: 'Reliability',
        content: 'We understand your needs and create/develop cloud software solutions with hi-tech resources with our team of experts exponentially. Also, we deliver the applications on time with prices that are budget-friendly. So, with us, you get 100% guaranteed results with precision and stability.',
        style: {"border": "5px solid #FFC2A0"},
        img: reliabiltyImage,

    },

    {
        id: 6,
        title: 'Experience',
        content: 'Over the years, our team of skilled software developers has created and retained many custom based cloud applications that are best in the platform. Also, they stand the test of time with excellent results. Hence, uplift your expertise with our cloud app solutions and shine.',
        style: {"border": "5px solid #A7ECFF"},
        img: experienceImage,

    },
]


  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
    <Container style={{ paddingTop: '40px'}}>
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontSize: '24px', marginBottom: '24px', fontFamily: 'Circular'}}>The Power of Praathee's Distinguished Cloud App Services</h1>
            
        </div>
        <p style={{fontSize: '18px', fontFamily: 'Circular-Book'}}>As premium cloud app developers, we aim to provide exceptional cloud services that meet the needs of every expert with agility. So, the types of cloud solutions that we offer are as follows.</p>
        <Row style={{marginTop: '50px'}}>
            {webCardData.map((val) => (
                
                <Col key={val.id} lg={4} md={6} sm={12}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, background: 'white', height: '90%', paddingLeft: '20px', paddingRight: '20px'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <h1 style={{fontSize: '18px', fontFamily: 'Circular'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px', fontFamily: 'Outfit-Regular', paddingBottom: '20px'}}>{val.content}</p>
                    </div>
            
                </Col>
            ))}
            
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default CloudServices