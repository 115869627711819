import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import career_form from '../../../assests/Career/career_form.svg'
import './kickstart.css'
import axios from "axios"

const Kickstart = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [ph_no, setPh_no] = useState("")
    const [location, setLocation] = useState("")
    const [position, setPosition] = useState("")


    const handleSubmit = (e) => {
        e.preventDefault()

        axios.post("http://localhost:8000/add-user",{name, email, ph_no})
        .then(res => {
            alert(res.data);
            setName("")
            setEmail("")
            setPh_no("")
        })
        .catch(err => {
            console.error(err)
            alert("error adding user")
        })
    }


  return (
    <>
    <Container fluid >
    <Container >
        
        <div>
                        <h1 style={{fontWeight: 500, fontSize: '36px', marginBottom: '16px', fontFamily: 'Circular'}}>Excited to kickstart a splendid career with us?</h1>
                        <div style={{lineHeight: '20px'}}>
                            <p style={{fontWeight: 400, fontSize: '22px', fontFamily: 'Outfit-Regular'}}>Send your resume to <a href='mailto:hr@praathee.com' style={{textDecoration: 'none', color: 'orange'}}>hr@praathee.com.</a> </p>
                            <p style={{fontWeight: 400, fontSize: '22px', fontFamily: 'Outfit-Regular', marginBottom: '40px'}}>If your skills and profile align with our requirements, our HR team will reach out to you.</p>
                        </div>
                    </div>
    
        <Row >
            <Col lg={6} className='kickstart_image'>
            <img src={career_form} style={{width: '100%'}} alt="" />
            </Col>

            <Col lg={6}>
            <h1 style={{fontSize: '30px', fontWeight: 500, marginBottom: '18px', fontFamily: 'Circular'}}>Fill out the form</h1>
            <div>
                <p style={{fontSize: '22px', fontWeight: 400, fontFamily: 'Outfit-Regular'}}>Begin your journey toward an exciting career At Praathee Technologies</p>
            </div>

            <Form onSubmit={handleSubmit} style={{paddingBottom: '10px'}}>
                <Row>
                    <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email Address" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" value={ph_no} onChange={(e) => setPh_no(e.target.value)} placeholder="Enter Phone Number" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Location</Form.Label>
                        <Form.Control type="text" value={location} onChange={(e) => setLocation(e.target.value) } placeholder="Enter Location" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Position Applied for</Form.Label>
                        <Form.Control type="text" value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Enter Position" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Resume</Form.Label>
                            <Form.Control type="file" />
                    </Form.Group>
                </Col>

                
                </Row>

                <Button type='submit' style={{fontSize: '18px', fontFamily: 'Circular', background: '#292929', border: 'none', padding: '10px 33.5px 10px 33.5px', marginTop: '20px', borderRadius: '50px'}}>Submit</Button>
            </Form>
            </Col>
        </Row>
        
        </Container>
    </Container>
    
    </>
  )
}

export default Kickstart