import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import webServicesImage1 from '../../../../assests/Services/Web_Application/webServicesImage1.svg'
import webServicesImage2 from '../../../../assests/Services/Web_Application/webServicesImage2.svg'
import webServicesImage3 from '../../../../assests/Services/Web_Application/webServicesImage3.svg'
import webServicesImage4 from '../../../../assests/Services/Web_Application/webServicesImage4.svg'
import webServicesImage5 from '../../../../assests/Services/Web_Application/webServicesImage5.svg'
import webServicesImage6 from '../../../../assests/Services/Web_Application/webServicesImage6.svg'
import floatingSquare from '../../../../assests/Services/Web_Application/floatingSquare.svg'
import { motion } from 'framer-motion'

const WebServices = () => {


    const webCardData = [{
        id: 1,
        title: 'Custom Application Development',
        content: 'Our custom-made applications enable seamless design and development of static or interactive websites tailored to your needs. Our skilled team ensures efficient, on-time delivery for projects of any complexity.',
        style: {"border": "5px solid #DFACFF"},
        img: webServicesImage1,

    },

    {
        id: 2,
        title: 'E - Commerce Development',
        content: 'We develop large-scale applications tailored to your business needs and methods. Using top-notch resources and frameworks, we create e-shops and B2B web portals within your budget.',
        style: {"border": "5px solid #90FFCB"},
        img: webServicesImage2,

    },

    {
        id: 3,
        title: 'Creative UI/UX Design',
        content: 'Utilizing UI and UX design with our well-engineered designers, we excellently conceptualize your visuals thus making your user interface and experiences stunning.',
        style: {"border": "5px solid #FFACC4"},
        img: webServicesImage3,

    },

    {
        id: 4,
        title: 'Application Testing & QA',
        content: 'Our team of experts doesn’t stop with creating and designing websites. Instead, once the development process is complete, a series of tests will be done to ensure the quality of the services 100%.',
        style: {"border": "5px solid #E4FF99"},
        img: webServicesImage4,

    },

    {
        id: 5,
        title: 'Support & Maitenance',
        content: 'Unlike other services, We offer 24/7 Customer support and give upgrades even after the product is completed and delivered.',
        style: {"border": "5px solid #FFC2A0"},
        img: webServicesImage5,

    },

    {
        id: 6,
        title: 'Seamless Web API and Integeration',
        content: 'We provide web solutions that serve as an excellent framework of regulations and protocols, ensuring sustainability seamlessly.',
        style: {"border": "5px solid #A7ECFF"},
        img: webServicesImage6,

    },
]


  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
    <Container style={{ paddingTop: '40px'}}>
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontSize: '24px', marginBottom: '24px', fontFamily: 'Circular'}}>Web App Development Services We Offer</h1>
            <p style={{fontSize: '18px', fontFamily: 'Circular-Book'}}>Harness the growth of your business potential with tailor-made web frameworks and elevate your success effectively.</p>
        </div>
        <Row style={{marginTop: '50px'}}>
            {webCardData.map((val) => (
                
                <Col key={val.id} lg={4} md={6} sm={12}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, background: 'white', height: '90%', paddingLeft: '20px', paddingRight: '20px'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={floatingSquare} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={floatingSquare} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={floatingSquare} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <h1 style={{fontSize: '18px', fontWeight: '600', fontFamily: 'DM-Sans-Semi-Bold'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px', fontFamily: 'Outfit-Regular', paddingBottom: '20px'}}>{val.content}</p>
                    </div>
            
                </Col>
            ))}
            
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default WebServices