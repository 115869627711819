import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import mapLocationImage from '../../../assests/Contact/contactLocation.svg'

const ContactLocation = () => {

   
     const mapUrl = "https://maps.app.goo.gl/NmMZ22SVtor3iGxA7"
    
    

  return (
    <>
        <Container style={{marginBottom: '50px', marginTop: '50px'}}>
            <Row>
                <Col style={{display: 'flex', alignItems: 'center', marginBottom: '45px'}}>
                    <a href={mapUrl} target='_blank' rel='noopener noreferrer'>
                        <img src={mapLocationImage} style={{width: '90%', cursor: 'pointer'}}/>
                    </a>
                </Col>

                <Col lg={6}>
            <h1 style={{fontSize: '30px', fontWeight: 500, marginBottom: '18px', fontFamily: 'Circular'}}>Get in Touch</h1>
            <div>
                <p style={{fontSize: '22px', fontWeight: 400, fontFamily: 'Outfit-Regular'}}>Don’t Hesitate To Contact Us Say Hello or Message</p>
            </div>

            <Form>
                <Row>
                    <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email Address" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter Phone Number" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Company" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Country</Form.Label>
                        <Form.Control type="text" placeholder="Enter Country" />
                </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Attachment</Form.Label>
                            <Form.Control type="file" />
                    </Form.Group>
                </Col>

                <Col lg={6} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                        <Form.Control type="text" placeholder="Enter your message" />
                </Form.Group>
                </Col>

                
                </Row>

                <Button style={{fontSize: '18px', fontFamily: 'Circular', background: '#292929', border: 'none', padding: '10px 33.5px 10px 33.5px', marginTop: '20px', borderRadius: '50px'}}>Send Message</Button>
            </Form>
            </Col>
            </Row>
        </Container>
    </>
  )
}

export default ContactLocation