import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import enterprisewhyus from '../../../../assests/Services/Enterprise_Software/enterpriseWhyUs.svg'
import whyus1 from '../../../../assests/Services/Enterprise_Software/whyus1.svg'
import whyus2 from '../../../../assests/Services/Enterprise_Software/whyus2.svg'
import whyus3 from '../../../../assests/Services/Enterprise_Software/whyus3.svg'
import whyus4 from '../../../../assests/Services/Enterprise_Software/whyus4.svg'
import whyus5 from '../../../../assests/Services/Enterprise_Software/whyus5.svg'
import whyus6 from '../../../../assests/Services/Enterprise_Software/whyus6.svg'
import whyusimage from '../../../../assests/Services/Enterprise_Software/whyusimage.svg'

const Enterprise_WhyUs = () => {


  const listData = [{
    id: 1,
    content: 'Tailored Solutions',
    img: whyus1,
  },

  {
    id: 2,
    content: 'Proven Scalability',
    img: whyus2,
  },

  {
    id: 3,
    content: 'Trusted Security & Compliance',
    img: whyus3,
  },

  {
    id: 4,
    content: 'Skilled Developers',
    img: whyus4,
  },

  {
    id: 5,
    content: '24/7 Constant Support',
    img: whyus5,
  },

  {
    id: 6,
    content: 'Budget-Friendly Resources',
    img: whyus6,
  },]

  return (
    <>
    <Container>

      <div style={{textAlign: 'center', marginBottom: '40px'}}><img style={{width: '50%'}} src={enterprisewhyus}/></div>

      <Row>
        <Col lg={6}>
          <img src={whyusimage} style={{width: '100%'}}/>
        </Col>

        <Col lg={6} style={{display: 'flex', justifyContent: 'center'}}>
        <ul style={{listStyle: 'none'}}>
        {listData.map((val) => (
          <div key={val.id}>
            <li style={{display: 'flex', paddingBottom: '30px', alignItems: 'center', fontFamily: 'Circular-Book', fontSize: '18px'}}><img src={val.img} style={{marginRight: '15px'}}/>{val.content}</li>
          </div>
        ))}
        </ul>
        </Col>

      </Row>
    </Container>
    </>
  )
}

export default Enterprise_WhyUs