import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import AboutPage from "./pages/About/AboutPage";
import CareerPage from "./pages/Career/CareerPage";
import ContactPage from "./pages/Contact/ContactPage";
import Web_App_Page from "./pages/Services/Web_App/Web_App_Page";
import Mobile_App_Page from "./pages/Services/Mobile_App/Mobile_App_Page";
import ProductPage from "./pages/Product/ProductPage";
import Cloud_App_Page from "./pages/Services/Cloud_App/Cloud_App_Page";
import Blockchain from "./pages/Services/Blockchain/Blockchain";
import It_Staffing from "./pages/Services/It_Staffing/It_Staffing";
import EnterpriseSoftware from "./pages/Services/Enterprise_Software/EnterpriseSoftware";
import Software_Maintenace from "./pages/Services/Software_Maintenance/Software_Maintenace";


const Routing = () => {
  return (
    <Router>
      
      <Routes  >
        <Route path="/" element={<HomePage />}/>
        <Route path="/about" element= {<AboutPage/>}/>
        <Route path = "/career" element= {<CareerPage/>}/>
        <Route path = "/contact" element={<ContactPage/>}/>
        <Route path="/web-application-service" element={<Web_App_Page/>}/>
        <Route path="/mobile-application-service" element={<Mobile_App_Page/>}/>
        <Route path="/product" element={<ProductPage/>}/>
        <Route path="/cloud-application-service" element={<Cloud_App_Page/>}/>
        <Route path="/blockchain-service" element={<Blockchain/>}/>
        <Route path="/It-Staffing-service" element={<It_Staffing/>}/>
        <Route path="/enterprise-software-service" element={<EnterpriseSoftware/>}/>
        <Route path="/software-maintenance-service" element={<Software_Maintenace/>}/>
       
      </Routes>
    </Router>
  );
};

export default Routing;
