import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import enterpriseBanner from '../../../../assests/Services/Enterprise_Software/enterpriseBanner.svg'
import './EnterpriseBanner.css'

const EnterpriseBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
        <Container>
            <Row>
                <Col lg={6} className='enterpriseBanner'>
                    <img src={enterpriseBanner} style={{width: '90%'}}/>
                </Col>

                <Col lg={6} md={12} sm={12} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <h1 style={{fontSize: '28px', fontFamily: 'Circular', paddingBottom: '20px'}}>Enterprise Software Development</h1>
                <p style={{fontSize: '20px', fontFamily: 'Outfit-Regular', lineHeight: '40px'}}>With our Enterprise custom software development services, you can uplift your business growth with automated applications and attain exclusive results rapidly. To build extensive applications consult our team now!</p>
                </Col>
            </Row>
        </Container>
    </Container>
    </>
  )
}

export default EnterpriseBanner