import React from 'react'
import { Container } from 'react-bootstrap'
import IosServiceImage from '../../../../assests/Services/Mobile_Application/IosServiceImage.svg'
import ipadFloatingImage from '../../../../assests/Services/Mobile_Application/ipadFloatingImage.svg'
import appleWatchFloatingImage from '../../../../assests/Services/Mobile_Application/appleWatchFloatingImage.svg'
import iphoneFloatingImage from '../../../../assests/Services/Mobile_Application/iphoneFloatingImage.svg'
import appleTvFloatingImage from '../../../../assests/Services/Mobile_Application/appleTVFloatingImage.svg'
import { motion } from 'framer-motion'
import './iosService.css'

const IosServices = () => {
  return (
    <>
    <Container style={{marginBottom: '40px'}}>
        <div style={{textAlign: 'center'}}>
        <h1 style={{fontSize: '24px', fontFamily: 'Circular', marginBottom: '20px', marginTop: '40px'}}>iOS App Development Services</h1>
        </div>
        <p style={{fontSize: '20px', fontFamily: 'Outfit-Regular', marginBottom: '24px'}}>At Praathee Technologies, we prioritize our customers' needs and deliver products accordingly. Besides this, your product needs will delivered instantly within the period that is discussed. This is one of the reasons, why we stand as the best IOS app development company for years, till now. Therefore, if you want your IOS application to be presented at the utmost potential, choose us and get highly qualified results. The services include, 
        </p>
        <p style={{fontSize: '20px', fontFamily: 'Outfit-Regular'}}>Expertise in building IOS apps for 
        </p>
        <div style={{textAlign: 'center', position: 'relative', width: '100%'}}>
            <img  src={IosServiceImage} style={{width: '40%'}}/>
            <motion.img className='ios_floating_image1' animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={ipadFloatingImage} style={{position: 'absolute', left: '65%', top: '10%'}}/>
            <motion.img className='ios_floating_image2' animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={appleWatchFloatingImage} style={{position: 'absolute', top: '70%', left: '64%'}}/>
            <motion.img className='ios_floating_image3' animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={iphoneFloatingImage} style={{position: 'absolute', left: '27%', top:'24%'}}/>
            <motion.img className='ios_floating_image4' animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={appleTvFloatingImage} style={{position: 'absolute', left: '25%', top: '70%'}}/>
        </div>
    </Container>
    </>
  )
}

export default IosServices