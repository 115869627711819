import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import saasImage from '../../../../assests/Services/Cloud_Application/saasImage.svg'
import paasImage from '../../../../assests/Services/Cloud_Application/paasImage.svg'
import iaasImage from '../../../../assests/Services/Cloud_Application/iaasImage.svg'
import animeImage from '../../../../assests/Services/Cloud_Application/animeImage.svg'
import { motion } from 'framer-motion'

const BlockchainServices = () => {


     const webCardData = [{
            id: 1,
            title: 'Custom Blockchain Developments',
            content: 'Every business dealings are different and so are the necessities. As a leading blockchain app developer, we create a custom-made solution that demonstrates our expertise in developing secure and cost-friendly services such as, Private, Public and Hybrid blockchain networks.',
            style: {"border": "5px solid #DFACFF"},
            img: saasImage,
    
        },
    
        {
            id: 2,
            title: 'Blockchain API Integration',
            content: "Seamless integration with effective blockchain functionality into one's application is our goal. So, if you want to facilitate data sharing with robust blockchain API integration, connect with our team and get added features like, Cryptocurrency transactions, 100% Safe Identity Verification and Smart Contracts Execution.",
            style: {"border": "5px solid #90FFCB"},
            img: paasImage,
    
        },
    
        {
            id: 3,
            title: 'Smart Contract Development',
            content: 'Smart contract development services are the most sought-after among businesses and other enterprises. Due to this factor, our team comes equipped with excellent resources. Hence, the best thing that you can do for your business growth is to hire our team. This way, you can develop blockchain platforms with regulatory standards effectively.',
            style: {"border": "5px solid #FFACC4"},
            img: iaasImage,
    
        },]


  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
        <Container>
            <div style={{textAlign: 'center', paddingBottom:'20px', paddingTop: '40px'}}>
                <h1 style={{fontSize: '24px', fontFamily: 'Circular'}}>Versatile Blockchain Development Services We Offer</h1>
            </div>
           
     <p style={{fontSize: '18px', fontFamily: 'Circular-Book'}}>At Praathee Technologies, we strive to bring a sense of security and a blend of perfection with the solutions and services that we offer. Therefore, when you get blockchain development services from us, you will get options that will help create and build your tailored solutions efficiently, promoting multiple transparency and business growth</p>
        
     <Row style={{marginTop: '50px'}}>
            {webCardData.map((val) => (
                
                <Col key={val.id} lg={4} md={6} sm={12}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, background: 'white', height: '90%', paddingLeft: '20px', paddingRight: '20px'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <h1 style={{fontSize: '18px', fontFamily: 'Circular'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px', fontFamily: 'Outfit-Regular', paddingBottom: '20px'}}>{val.content}</p>
                    </div>
            
                </Col>
            ))}
            
        </Row>
        
        </Container>
    </Container>
    </>
  )
}

export default BlockchainServices