import React, { useEffect } from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import Overview from './Our_Overview/Overview'
import AboutUs from './AboutUs/AboutUs'
import WhatWeDo from './WhatWeDo/WhatWeDo'
import WhatMakesUsBest from './WhatMakesUsBest/WhatMakesUsBest'
import OurTrustedClients from './TrustedClients/OurTrustedClients'
import ClientReviews from './ClientReviews/ClientReviews'
import Footer from '../../common/Footer/Footer'
import NavBar from '../../common/NavBar/NavBar'
import Blogs from './Blogs/Blogs'
import { Helmet } from "react-helmet";


const HomePage = () => {



  useEffect(() => {
    document.title = "Software Development Service in India | Custom Web & Mobile Solutions"
  }, [])


  return (
    <>

    

    <NavBar/>
    <HomeBanner/>
    <Overview/>
    <AboutUs/>
    <WhatWeDo/>
    <WhatMakesUsBest/>
    <ClientReviews/>
    <OurTrustedClients/>
    
    {/* <Blogs/> */}
    <Footer/>
    
    </>
  )
}

export default HomePage