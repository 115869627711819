import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import recruitment1 from '../../../../assests/Services/IT_Staffing/recruitment1.svg'
import recruitment2 from '../../../../assests/Services/IT_Staffing/recruitment2.svg'
import recruitment3 from '../../../../assests/Services/IT_Staffing/recruitment3.svg'
import recruitment4 from '../../../../assests/Services/IT_Staffing/recruitment4.svg'
import recruitment5 from '../../../../assests/Services/IT_Staffing/recruitment5.svg'
import recruitment6 from '../../../../assests/Services/IT_Staffing/recruitment6.svg'
import animeImage from '../../../../assests/Services/Cloud_Application/animeImage.svg'
import { motion } from 'framer-motion'

const Recruitment = () => {


    const webCardData = [{
                id: 1,
                title: 'Recruitment Process Outsourcing ',
                content: 'We offer a cost-effective, streamlined approach to screening and hiring employees. Our strategy includes building a tailored RPO solution, thorough candidate screening based on qualifications and skills, managing the interview process, improving offer acceptance rates, and providing onboarding support to ease candidates into their new roles.',
                style: {"border": "5px solid #DFACFF"},
                img: recruitment1,
        
            },
        
            {
                id: 2,
                title: 'Professional Staffing and Services',
                content: "When searching for a reliable candidate that meets your expertise having the guidance of professional staff is necessary. Our HR recruiter and team are equipped with so much skill and experience that seamless integration of specialized projects to your team is best ensured. So, get in touch with our team and get your IT staffing services now.",
                style: {"border": "5px solid #90FFCB"},
                img: recruitment2,
        
            },
        
            {
                id: 3,
                title: 'Project-Based Consulting',
                content: 'If you need candidates who meet your project needs specifically then finding candidates who understand and deliver your project on time is necessary. At Praathee, we give high priority to our client’s needs. So, based on your project needs, we help hire absolutely good candidates, making facilitation easy.',
                style: {"border": "5px solid #FFACC4"},
                img: recruitment3,
        
            },
        
            {
                id: 4,
                title: 'Diversity, Inclusion, and Innovation ',
                content: 'We as a team understand how important it is to stay updated with new trends and technologies. So, keeping this in mind, we have outsourced candidates who are not just skilled in their areas of expertise. Instead, we have excellent candidates who can adapt and work according to the updates without any trouble seamlessly.',
                style: {"border": "5px solid #E4FF99"},
                img: recruitment4,
        
            },
        
            {
                id: 5,
                title: 'Maintenance & Support',
                content: "Equipped with well-built technology, we stand the best in maintaining and tracking staff and inventory efficiently. So, with our skilled professionals, all your data will be safe and you can get support whenever it's necessary quickly.",
                style: {"border": "5px solid #FFC2A0"},
                img: recruitment5,
        
            },
        
            {
                id: 6,
                title: 'Expert Technical Assessments',
                content: 'We always believe in flexibility and reliability in every service that we offer. So, before selecting an ideal candidate for your company, we will follow a stringent test and series, resulting in efficiency. This way, you can get top-notch IT staffing services from our end and succeed.',
                style: {"border": "5px solid #A7ECFF"},
                img: recruitment6,
        
            },]


  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
        <Container>
            <div style={{textAlign: 'center', paddingBottom: '18px', paddingTop: '40px'}}>
            <h1 style={{fontSize: '24px', fontFamily: 'Circular'}}>Praathee's Recruitment Process - What Do We Offer?</h1>
            </div>

            <p style={{fontFamily: 'Circular-Book', fontSize: '18px'}}>At Praathee, we are equipped with robust technologies and a team who are highly skilled in their expertise. As a client, your recruitment process over here will be given higher priority, and the efficiency with which we find you a suitable candidate with be completely efficient and 100% professional. To know more about the process, read on!</p>
        
            <Row style={{marginTop: '50px'}}>
            {webCardData.map((val) => (
                
                <Col key={val.id} lg={4} md={6} sm={12}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, background: 'white', height: '90%', paddingLeft: '20px', paddingRight: '20px'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={animeImage} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <h1 style={{fontSize: '18px', fontFamily: 'Circular'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px', fontFamily: 'Outfit-Regular', paddingBottom: '20px'}}>{val.content}</p>
                    </div>
            
                </Col>
            ))}
            
        </Row>
        
        </Container>
    </Container>
    </>
  )
}

export default Recruitment