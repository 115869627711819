// import React, { useRef } from "react";
// import { Container } from "react-bootstrap";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";

// import "./ClientReviews.css";
// import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import ansReviewImage from '../../../assests/Home/Reviews/ANS_review_logo.svg'
// import eonianReviewLogo from '../../../assests/Home/Reviews/EONIAN_review_logo.svg'

// const ClientReviews = () => {

//   const swiperRef = useRef(null);
//   return (
//     <>
//       <Container>
//         <div style={{ textAlign: "center", marginBottom: "20px" }}>
//           <h1
//             style={{
//               fontWeight: 500,
//               fontSize: "36px",
//               lineHeight: "45.54px",
//               marginBottom: "14px",
//               fontFamily: 'Circular'
//             }}
//           >
//             Reviews that speaks the volume
//           </h1>
//           <p style={{ fontSize: "20px", fontFamily: 'Circular-Book' }}>
//             Don't just take our words for it, hear what people says about us
//           </p>
//         </div>

//         <div>
//           <Swiper
//             modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
//             spaceBetween={30}
//             slidesPerView={3}
            
//             pagination={{ clickable: true }}
//             centeredSlides={true}
//             loop={true}
            
//             breakpoints={{
//               1024: {
//                 slidesPerView: 3, 
//               },
//               768: {
//                 slidesPerView: 2,
//                 centeredSlides: false 
//               },
//               0: {
//                 slidesPerView: 1,
//                 centeredSlides: false, 
//               },
//             }}
      
           
//             onSwiper={(swiper) => (swiperRef.current = swiper)} 
//           onResize={() => swiperRef.current?.update()}
//             onSlideChange={() => console.log("slide change")}
//             className="custom-swiper"
//           >
//             <SwiperSlide>
              
//               <div style={{padding: '20px', fontFamily: 'Circular-Book'}} className="custom-card card shadow">
//               <img className="review_image" src={ansReviewImage} alt="" />
//                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                 Dignissimos architecto suscipit voluptatem, repellat
//                 consequuntur ea, molestiae quae quaerat ex dicta sed. Totam
//                 consequatur ad fuga inventore iusto obcaecati sunt quisquam.
//               </div>
//             </SwiperSlide>
//             <SwiperSlide>
//               <div style={{padding: '20px', fontFamily: 'Circular-Book'}} className="custom-card card shadow">
//                 <img className="review_image" src={eonianReviewLogo} alt="" />
//                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                 Dignissimos architecto suscipit voluptatem, repellat
//                 consequuntur ea, molestiae quae quaerat ex dicta sed. Totam
//                 consequatur ad fuga inventore iusto obcaecati sunt quisquam.
//               </div>
//             </SwiperSlide>
//             <SwiperSlide>
//               <div style={{padding: '20px', fontFamily: 'Circular-Book'}} className="custom-card card shadow">
//                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                 Dignissimos architecto suscipit voluptatem, repellat
//                 consequuntur ea, molestiae quae quaerat ex dicta sed. Totam
//                 consequatur ad fuga inventore iusto obcaecati sunt quisquam.
//                 lorem50
//               </div>
//             </SwiperSlide>
//             <SwiperSlide>
//               <div style={{padding: '20px', fontFamily: 'Circular-Book'}} className="custom-card card shadow">
//                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                 Dignissimos architecto suscipit voluptatem, repellat
//                 consequuntur ea, molestiae quae quaerat ex dicta sed. Totam
//                 consequatur ad fuga inventore iusto obcaecati sunt quisquam.
//               </div>
//             </SwiperSlide>
//             <SwiperSlide>
//               <div style={{padding: '20px', fontFamily: 'Circular-Book'}} className="custom-card card shadow">
//                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                 Dignissimos architecto suscipit voluptatem, repellat
//                 consequuntur ea, molestiae quae quaerat ex dicta sed. Totam
//                 consequatur ad fuga inventore iusto obcaecati sunt quisquam.
//               </div>
//             </SwiperSlide>
//           </Swiper>
//         </div>
//       </Container>
//     </>
//   );
// };

// export default ClientReviews;


import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "./ClientReviews.css";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ansReviewImage from "../../../assests/Home/Reviews/ANS_review_logo.svg";
import eonianReviewLogo from "../../../assests/Home/Reviews/EONIAN_review_logo.svg";
import ravindraImage from '../../../assests/Home/Reviews/ravindraImage.svg'

const ClientReviews = () => {
  const [key, setKey] = useState(0); 

  const handleResize = () => {
    setKey((prevKey) => prevKey + 1); 
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize); 
    return () => window.removeEventListener("resize", handleResize); 
  }, []);

  return (
    <Container>
      <div style={{ textAlign: "center" }}>
        <h2
          style={{
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "45.54px",
            marginBottom: "14px",
            fontFamily: "Circular",
          }}
        >
          Reviews that speak the volume
        </h2>
        <p style={{ fontSize: "20px", fontFamily: "Circular-Book" }}>
          Don't just take our words for it, hear what people say about us
        </p>
      </div>

      <div>
        <Swiper
          key={key} 
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}
          pagination={{ clickable: true }}
          // centeredSlides={true}
          autoplay={{
            delay: 3000, 
            disableOnInteraction: false, 
          }}
          // loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            0: {
              slidesPerView: 1,
              centeredSlides: false,
            },
          }}
          className="custom-swiper"
        >
          <SwiperSlide>
            <div style={{ padding: "20px", fontFamily: "Circular-Book", fontSize: '20px' }} className="custom-card card shadow">
              <img className="review_image" src={ansReviewImage} alt="Review Logo" style={{marginBottom: '8px'}}/>
              <p style={{lineHeight: '201%'}}>
              Partnering with Prathee Media Pvt Ltd., for our software development projects has been nothing short of a game-changer. Their professionalism, attention to detail, and commitment to delivering top-quality solutions have consistently exceeded our expectations. Every project we have collaborated on has been met with unparalleled expertise. Their team is incredibly responsive, adaptable, and efficient, making the entire development process seamless. We highly value our partnership with Prathee Media Pvt Ltd., and look forward to many more successful collaborations in the future.
              </p>
            <div style={{display: 'flex', marginTop: '10px'}}>
              <img src={ravindraImage} style={{width: '47px', height: '47px', paddingRight: '8px'}}/>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <span style={{fontSize: '20px', fontFamily: 'Circular'}}>Ravindra</span>
              <span style={{fontSize: '14px', fontFamily: 'Circular-Book', color: '#000000'}}>Founder</span>
              </div>
            </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div style={{ padding: "20px", fontFamily: "Circular-Book" }} className="custom-card card shadow">
              <img className="review_image" src={eonianReviewLogo} alt="Review Logo" />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos architecto suscipit voluptatem,
              repellat consequuntur ea, molestiae quae quaerat ex dicta sed. Totam consequatur ad fuga inventore iusto
              obcaecati sunt quisquam.
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div style={{ padding: "20px", fontFamily: "Circular-Book" }} className="custom-card card shadow">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos architecto suscipit voluptatem,
              repellat consequuntur ea, molestiae quae quaerat ex dicta sed. Totam consequatur ad fuga inventore iusto
              obcaecati sunt quisquam. lorem50
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ padding: "20px", fontFamily: "Circular-Book" }} className="custom-card card shadow">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos architecto suscipit voluptatem,
              repellat consequuntur ea, molestiae quae quaerat ex dicta sed. Totam consequatur ad fuga inventore iusto
              obcaecati sunt quisquam.
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ padding: "20px", fontFamily: "Circular-Book" }} className="custom-card card shadow">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos architecto suscipit voluptatem,
              repellat consequuntur ea, molestiae quae quaerat ex dicta sed. Totam consequatur ad fuga inventore iusto
              obcaecati sunt quisquam.
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </Container>
  );
};

export default ClientReviews;

