import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import home_aboutus_image from '../../../assests/Home/home_aboutus_image.png'
import './aboutus.css'

const AboutUs = () => {
  return (
    <>
    <Container style={{marginTop: '50px', marginBottom: '45px'}}>
      <Row>
        <Col lg={5} className='home_about_image'>
        <img src={home_aboutus_image} style={{width: '100%', height: '100%', objectFit: 'contain'}} alt="" />
        </Col>

        <Col lg={7}>
        <h1 style={{fontSize: '36px', fontWeight: 500, marginBottom: '38px', fontFamily: 'Circular'}}>Experts in Software Development Solutions</h1>
        <div style={{fontSize: '20px', fontWeight: 400, listStyle: 'none', fontFamily: 'Outfit-Regular'}}>
          <p style={{lineHeight: '38px'}}>With 7+ years of experience, we excel in software solution expertise. Additionally, we provide various services in a timely and budget-friendly manner. </p>
          <p style={{lineHeight: '38px'}}>As the best software development company in India, we offer versatile and seamless services. </p>
          <p style={{lineHeight: '38px'}}>So, if you are a startup or business looking for remarkable software development services, connect with us and achieve results immediately.</p>
        </div>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default AboutUs