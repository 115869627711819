import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import competitve_image from '../../../assests/Career/competitive_compensation.svg'
import comprehensive_image from '../../../assests/Career/comprehensive_benefits.svg'
import supportive_image from '../../../assests/Career/supportive_work_culture.svg'
import professional_development from '../../../assests/Career/professional_development.svg'

const BenefitsOfJoining = () => {
  return (
    <>
    <Container style={{marginTop: '40px', marginBottom: '80px'}}>
        <div style={{textAlign: 'center' }}>
            <h1 style={{fontWeight: 500, fontSize: '36px',marginBottom: '40px', fontFamily: 'Circular'}}>Benefits of Joining Our Company</h1>
        </div>

        <Row style={{rowGap: '30px'}}>
            <Col lg={3} md={6}>
                <img style={{width: '100%'}} src={competitve_image} alt="" />
            </Col>

            <Col lg={3} md={6}>
                <img style={{width: '100%'}} src={comprehensive_image} alt="" />
            </Col>

            <Col lg={3} md={6}>
                <img style={{width: '100%'}} src={supportive_image} alt="" />
            </Col>

            <Col lg={3} md={6}>
                <img style={{width: '100%'}} src={professional_development} alt="" />
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default BenefitsOfJoining