import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import mobileBanner from '../../../../assests/Services/Mobile_Application/mobileBanner.svg'
import './mobileBanner.css'

const MobileBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
        <Container style={{ marginBottom: '40px'}}>
            <Row>
                <Col lg={6} sm={12} style={{display: 'flex'}} className='mobile_banner'>
                    <img style={{width: '100%'}} src={mobileBanner}/>
                </Col>

                <Col lg={6} sm={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <h1 style={{fontSize: '28px', marginBottom: '32px', fontFamily: 'Circular'}}>Mobile App Development</h1>
                    <div style={{fontSize: '20px', lineHeight: '36px', fontFamily: 'Outfit-Regular'}}>
                    <p>With years of expertise and over 300 successful software solutions delivered, Praathee Technologies excels in designing and developing exceptional mobile applications. From smartphone apps to custom digital assistants, we prioritize easy customization and high-quality services.</p>
                    <p>We leverage cutting-edge tools like Swift for iOS and Java for Android to ensure superior performance and value in every mobile app we create.</p>
                    </div>
                </Col>
            </Row>
        </Container>
        </Container>
    </>
  )
}

export default MobileBanner