import React, { useEffect } from 'react'
import WebBanner from './Web_Banner/WebBanner'
import WebServices from './Web_App_Services/WebServices'
import WebWhyUs from './Web_Why_us/WebWhyUs'
import Footer from '../../../common/Footer/Footer'
import NavBar from '../../../common/NavBar/NavBar'

const Web_App_Page = () => {


  useEffect(() => {
    document.title = "Web App Development"
  }, [])

  return (
    <>
    <NavBar/>
    <WebBanner/>
    <WebServices/>
    <WebWhyUs/>
    <Footer/>
    </>
  )
}

export default Web_App_Page