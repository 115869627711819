import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import careerBanner from '../../../assests/Career/career_banner.svg'
import './career_banner.css'

const CareerBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
    <Container>
        <Row>
            <Col lg={6} className='career_banner'>
                <img style={{width: '90%'}} src={careerBanner} alt="" />
            </Col>

            <Col lg={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <h1 style={{fontWeight: 500, fontSize: '46px', marginBottom: '30px', fontFamily: 'Circular'}}>Join Our Team of Innovators and Creators</h1>
                <p style={{fontWeight: 400, fontSize: '22px', lineHeight: '46px', fontFamily: 'Outfit-Regular'}}>At Praathee Technologies, we believe in the power of diversity, creativity, and collaboration. With our team comprised of brilliant minds from diverse backgrounds, we work together to solve, challenges Complex and Drive meaningful impact and much more.</p>
            </Col>
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default CareerBanner