import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import react_logo from '../../../assests/About/reactjs.svg'
import react_native_logo from '../../../assests/About/reactnative.svg'
import node_js from '../../../assests/About/nodejs.svg'
import dotnet_logo from '../../../assests/About/dotnet.svg'
import flutter_logo from '../../../assests/About/flutter.svg'
import angular_logo from '../../../assests/About/angular.svg'

const Platform = () => {
  return (
    <>
    <Container fluid style={{background: '#E9F0FF'}}>
    <Container >
      <div style={{textAlign: 'center', marginBottom: '60px'}}>
      <h2 style={{fontFamily: 'Circular', fontSize: '36px', paddingTop: '40px'}}>We work with below platforms</h2>
      </div>
        <Row style={{rowGap: '80px', paddingBottom: '40px'}}>
            <Col style={{textAlign: 'center'}} lg={4}>
            <img src={react_logo} alt="" />
            </Col>

            <Col style={{textAlign: 'center'}} lg={4}>
            <img src={react_native_logo} alt="" />
            </Col>

            <Col style={{textAlign: 'center'}} lg={4}>
            <img src={node_js} alt="" />
            </Col>

            <Col style={{textAlign: 'center'}} lg={4}>
            <img src={dotnet_logo} alt="" />
            </Col>

            <Col style={{textAlign: 'center'}} lg={4}>
            <img src={flutter_logo} alt="" />
            </Col>

            <Col style={{textAlign: 'center'}} lg={4}>
            <img src={angular_logo} alt="" />
            </Col>
        </Row>

        <Row>
            
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default Platform