import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import webBanner from '../../../../assests/Services/Web_Application/Web_App_Service_Banner.svg'
import './webBanner.css'

const WebBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
    <Container>
        
        <Row>
            <Col lg={6} style={{display: 'flex'}} className='web_service_banner'>
                <img src={webBanner} style={{width: '100%'}}></img>
            </Col>

            <Col lg={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', lineHeight: '210%'}}>
                <h1 style={{marginBottom: '32px', fontWeight: 'medium', fontSize: '28px', fontFamily: 'Circular'}}>Web App Development</h1>
                <p style={{fontFamily: 'Outfit-Regular', fontSize: '20px', lineHeight: '36px'}}>Praathee Technologies specializes in developing enterprise web apps, web portals, and customer-facing applications—all at competitive prices. We craft powerful yet user-friendly interfaces tailored to meet client needs.</p>
                <p style={{fontFamily: 'Outfit-Regular', fontSize: '20px', lineHeight: '36px'}}> From custom web apps to complex enterprise software, we provide complete end-to-end development services. Our expert team utilizes advanced tools and industry knowledge to deliver exceptional results.</p>
            </Col>
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default WebBanner