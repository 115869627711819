import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import supportImage from '../../../assests/Contact/support.svg'
import hrTeamImage from '../../../assests/Contact/hrTeam.svg'
import salesTeamImage from '../../../assests/Contact/salesTeam.svg'
import locationImage from '../../../assests/Contact/location.svg'

const ContactCards = () => {

    const cardData = [{
        id: 1,
        img: supportImage,
        title: 'Support',
        para1: 'Mail ID - ',
        email: 'support@praathee.com',
        
    },

    {
        id: 2,
        img: hrTeamImage,
        title: 'HR Team',
        para1: 'Mail ID - ',
        email: 'hr@praathee.com',
        para2: 'Mobile - ',
        mobile: '89256 54157',
    },

    {
        id: 3,
        img: salesTeamImage,
        title: 'Sales Team',
        para1: 'Mail ID - ',
        email: 'sales@praathee.com', 
        para2: 'Mobile - ',
        mobile: '90432 04157'
    },

    {
        id: 4,
        img: locationImage,
        title: 'Location',
        para1: 'Innovspace Coworking No. 11/4, Pooja Garden, Kalapatti Main Rd, Civil Aerodrome Post, Coimbatore, India - 641 014',
    },

]


  return (
    <>
    <Container style={{marginBottom: '20px'}}>
        <Row style={{rowGap: '30px'}}>
            {cardData.map((val) => (
                            <Col key={val.id} lg={3} md={6} sm={6}>
                            <div style={{border: '2px solid #E2E2E2', textAlign: 'center', height: '100%', borderRadius: '10px', paddingLeft: '20px', paddingRight: '20px'}}>
                                <img src={val.img} style={{marginTop: '18px', marginBottom: '20px'}}></img>
                                <h1 style={{fontSize: '24px', fontWeight: 'medium', marginBottom: '20px', fontFamily: 'Outfit-Regular'}}>{val.title}</h1>
                                <p style={{fontFamily: 'Outfit-Regular'}}>{val.para1}<a style={{color: '#212529', textDecoration: 'none'}} href={`mailto:${val.email}`}>{val.email}</a></p>
                                <p style={{fontFamily: 'Outfit-Regular'}}>{val.para2}<a style={{color: '#212529', textDecoration: 'none'}} href={`tel:${val.mobile}`}>{val.mobile}</a></p>

                            </div>
                            </Col>
            ))}

        </Row>
    </Container>
    </>
  )
}

export default ContactCards