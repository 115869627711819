import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import progressImage from '../../../assests/About/progress.svg'
import './progress.css'

const Progress = () => {
  return (
    <>
    <Container>
        <Row>
            <Col lg={5} style={{display: 'flex', justifyContent: 'center'}} className='about_progress'>
                <img style={{width: '90%'}} src={progressImage} alt="" />
            </Col>


            <Col lg={7} style={{display: 'flex', alignItems: 'center'}}>
                <ul style={{fontWeight: 400, fontSize: '22px', lineHeight: '45px', fontFamily: 'Outfit-Regular'}}>
                    <li> Your success is our priority.</li>
                    <li>From start to finish, we ensure smooth, reliable, and hassle-free services, delivered with professionalism and precision. </li>
                    <li>We provide cutting-edge IT management and software development solutions to help you innovate, transform, and achieve your business goals effortlessly.</li>

                    
                </ul>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Progress