import React, { useEffect } from 'react'
import ProductBanner from './ProductBanner/ProductBanner'
import NavBar from '../../common/NavBar/NavBar'
import ProductOverview from './ProductOverview/ProductOverview'
import Features from './Features/Features'
import Benefits from './Benefits/Benefits'
import Footer from '../../common/Footer/Footer'

const ProductPage = () => {


  useEffect(() => {
    document.title = "Product"
  }, [])


  return (
    <>
    <NavBar/>
    <ProductBanner/>
    <ProductOverview/>
    <Features/>
    <Benefits/>
    <Footer/>
    </>
  )
}

export default ProductPage