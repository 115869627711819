import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import whatMakesImage from '../../../assests/Home/home_whatmakes_us.png'
import homeUserIcon from '../../../assests/Home/home_user_icon.svg'
import homeConsistentIcon from '../../../assests/Home/home_consistent_software_icon.svg'
import homeDeliveryIcon from '../../../assests/Home/home_delivery_icon.svg'
import homeCustomMadeIcon from '../../../assests/Home/home_custom_made_design_icon.svg'
import homeQualityIcon from '../../../assests/Home/home_quality_assurance.svg'
import './whatmakeshome.css'

const WhatMakesUsBest = () => {
  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
    <Container style={{marginBottom: '65px', paddingBottom: '40px'}}>
        <div style={{marginBottom: '40px', textAlign: 'center', fontFamily: 'Circular'}}>
        <h2 style={{fontWeight: 500, fontSize: '36px'}}>What makes us the Best</h2>
        </div>
        <Row>
            <Col lg={7} className='home_whatmakes_image' style={{display: 'flex', alignItems: 'center'}}>
              <img src={whatMakesImage} style={{width: '90%'}} alt="" />
            </Col>

            <Col style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{fontSize: '20px', paddingTop: '14px', fontFamily: 'Circular-Book'}}>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '45px'}}>
            <img style={{paddingRight: '20px'}} src={homeUserIcon} alt='userIcon'/><div>Professionalism with excellent guidance</div>
            </div>

            <div style={{display: 'flex', alignItems: 'center', marginBottom: '45px'}}>
            <img style={{paddingRight: '20px'}} src={homeConsistentIcon} alt=''/><div>Consistent software solutions</div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '45px'}}>
                <img style={{paddingRight: '20px'}} src={homeDeliveryIcon} alt=''/><div>On -time delivery</div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '45px'}}>
                <img style={{paddingRight: '20px'}} src={homeCustomMadeIcon} alt=''/><div>Custom - made design</div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '45px'}}>
                <img style={{paddingRight: '20px'}} src={homeQualityIcon} alt=''/><div>100% quality assurance</div>
                </div>
            </div>
            </Col>
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default WhatMakesUsBest