import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import mobileServiceImage1 from '../../../../assests/Services/Mobile_Application/mobileServiceImage1.svg'
import mobileServiceImage2 from '../../../../assests/Services/Mobile_Application/mobileServiceImage2.svg'
import mobileServiceImage3 from '../../../../assests/Services/Mobile_Application/mobileServiceImage3.svg'
import mobileServiceImage4 from '../../../../assests/Services/Mobile_Application/mobileServiceImage4.svg'
import mobileServiceImage5 from '../../../../assests/Services/Mobile_Application/mobileServiceImage5.svg'
import mobileServiceImage6 from '../../../../assests/Services/Mobile_Application/mobileServiceImage6.svg'
import floatingSquare from '../../../../assests/Services/Mobile_Application/floatingSquare.svg'
import { motion } from 'framer-motion'

const MobileServices = () => {


    const mobileServices = [{
        id: 1,
        title: 'Planning & Understanding',
        content: 'Your needs and the scope of the app will be discussed and analyzed.',
        img: mobileServiceImage1,
        style: {"border": "5px solid #DFACFF"},
    },

    {
        id: 2,
        title: 'Brainstorming & Analysis',
        content: 'App features and tools that are to be used will be confirmed.',
        img: mobileServiceImage2,
        style: {"border": "5px solid #90FFCB"},
    },

    {
        id: 3,
        title: 'Designing',
        content: 'Development of user interface and user experiences will be in process.',
        img: mobileServiceImage3,
        style: {"border": "5px solid #FFACC4"},
    },

    {
        id: 4,
        title: 'Application Testing & QA',
        content: 'App Coding, Backend infrastructure, and Integrating third-party APIs and services will take place.',
        img: mobileServiceImage4,
        style: {"border": "5px solid #E4FF99"},
    },

    {
        id: 5,
        title: 'Testing',
        content: 'Bugs and errors will be fixed, ensuring the quality of the app. ',
        img: mobileServiceImage5,
        style: {"border": "5px solid #FFC2A0"},
    },

    {
        id: 6,
        title: 'Maintenance & Services',
        content: 'New updates, New features, and Bug fixation will be done accordingly.',
        img: mobileServiceImage6,
        style: {"border": "5px solid #A7ECFF"},
    },
]



  return (
    <>
    <Container fluid style={{background: '#EDEDFD'}}>
        <Container style={{paddingTop: '50px', paddingBottom: '20px'}}>
            <div style={{textAlign: 'center'}}>
                <h1 style={{fontSize: '20px', marginBottom: '24px', fontFamily: 'Circular'}}>What Makes Us The Best In Mobile App Development Services?</h1>
                <p style={{fontSize: '18px', fontFamily: 'Circular-Book'}}>Our expert team delivers exceptional mobile apps, from scratch to upgrades, with a process that guarantees excellence</p>
            </div>
            <Row style={{marginTop: '50px'}}>
            {mobileServices.map((val) => (
                
                <Col key={val.id} lg={4} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                                    {console.log(val)}

                    <div style={{ borderTop:val?.style?.border, width: '90%', height: '90%', background: 'white'}}>
                        <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px', position: 'relative', zIndex: 2}}>
                            <img src={val.img}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={floatingSquare} style={{position: 'absolute', left: '63%', bottom: '6px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={floatingSquare} style={{position: 'absolute', left: '38%', bottom: '17px', zIndex: '-2'}}/>
                            <motion.img animate={{x:[-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={floatingSquare} style={{position: 'absolute', left: '68%', bottom: '41px', zIndex: '-2'}}/>
                        </div>
                        <div style={{paddingLeft: '20px', paddingRight: '20px', paddingBottom: '45px'}}>
                        <h1 style={{fontSize: '18px', fontWeight: '600'}}>{val.title}</h1>
                        <p style={{fontSize: '15px', marginBottom: '44px'}}>{val.content}</p>
                        </div>
                    </div>
            
                </Col>
            ))}
            
        </Row>
        </Container>
        </Container>
    </>
  )
}

export default MobileServices