import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import blockchainBanner from '../../../../assests/Services/Blockchain/blockchainBanner.svg'
import './blockBanner.css'

const BlockchainBanner = () => {
  return (
    <>
    <Container fluid style={{background: "linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)"}}>
        <Container>
            <Row>
                <Col lg={5} md={12} sm={12} xs={12} className='blockchainBanner' style={{display: 'flex'}}>
                    <img src={blockchainBanner} style={{width: '100%'}}/>
                </Col>


                <Col lg={7} md={12} sm={12} xs={12}>
                <h1 style={{fontFamily: 'Circular', fontSize: '28px', paddingBottom: '15px'}}>Blockchain Technology</h1>
                <p style={{fontFamily: 'Outfit-Regular', fontSize: '20px', lineHeight: '40px'}}>As the best software development company with exceptional services, We Praathee Technologies marks a significant position in Blockchain development. With a better understanding of blockchain usage for businesses, our blockchain app developers help elevate your transparency, security, and potential to the next level. Besides that, we also offer versatile options specialized in custom-made blockchain applications, smart contract development decentralized development, etc. So, for all your blockchain development doubts and needs, schedule a meeting with us and gain solutions swiftly.</p>
                </Col>
            </Row>
        </Container>

    </Container>
    </>
  )
}

export default BlockchainBanner