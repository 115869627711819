import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import productBanner from '../../../assests/Product/productBanner.svg'
import testBanner from '../../../assests/Product/testImage.jpeg'
import './productBanner.css'

const ProductBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
    <Container>
        <Row>
            <Col l={6} className='product_banner'>
                <img src={productBanner} style={{width: '90%'}}/>
            </Col>

            <Col lg={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <h1 style={{fontFamily: 'Circular', fontSize: '28px'}}>PraDocs - Document Management Software</h1>
            <div style={{fontFamily: 'Outfit-regular', fontSize: '20px'}}>
            <p style={{lineHeight: '46px'}}>At Praathee Technologies, we are always on the lookout and stay one step ahead with building products that best meet business needs. While we have many excellent resources, our latest product Document Management Software - Pradocs marks its significance amongst all. Why?</p>
            <p style={{lineHeight: '46px'}}>With our cutting-edge and simplified solution, you can say goodbye to the manual documentation process and say hi to the digitization of documents enthusiastically.</p>
            </div>
            </Col>
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default ProductBanner