import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Footer.css'
import copyrights_image from '../../assests/Footer/footer_copyrights.svg'
import footer_praathee_logo from '../../assests/Footer/footer_praathee_logo.svg' 
import footer_facebook from '../../assests/Footer/footer_facebook.svg'
import footer_instagram from '../../assests/Footer/footer_instagram.svg'
import footer_linkedin from '../../assests/Footer/footer_linkedin.svg'
import footer_twitter from '../../assests/Footer/twitter_logo.svg'

const Footer = () => {

  const text = `No.11/4 Pooja Garden,
Kalapatti Main Rd, Indira Nagar,
Civil Aerodrome Post, Coimbatore,
Tamil Nadu, India - 641014`


  return (
    <>
    <Container fluid style={{background: '#0A0F2F', paddingTop: '47.5px', color: '#FFFFFF'}}>
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12} style={{marginBottom: '15px'}}>
          <div>
          <img style={{marginBottom: '8px'}} src={footer_praathee_logo} alt="" />
          <p style={{fontWeight: 500, fontSize: '10px', letterSpacing: '8px', fontFamily: 'Circular'}}>INNOVATE TODAY</p>
          </div>

          <p style={{fontSize: '16px', fontWeight: 400, lineHeight: '30.84px', whiteSpace: 'pre-line', fontFamily: 'DM-Sans-Regular'}}>{text}</p>
          <div style={{display: 'flex', gap: '20px', marginTop: '22px'}}>

            <a href='https://www.facebook.com/Praathee/'><img src={footer_facebook} alt="" /></a>
            <a href='https://www.instagram.com/praathee_technologies/'><img src={footer_instagram} alt="" /></a>
            <a href='https://www.linkedin.com/company/praathee-technologies/'><img src={footer_linkedin} alt="" /></a>
            <a href='https://x.com/praathee_2017'><img src={footer_twitter} alt="" /></a>
          </div>
          </Col>

          <Col lg={2} md={6} sm={12}>
            <div className='footer_content' style={{fontFamily: 'DM-Sans-Regular'}}>
              <h3 >Company</h3>
              <p><a href='/' style={{textDecoration: 'none', color: '#FFFFFF'}}>Home</a></p>
              <p><a href='/about' style={{textDecoration: 'none', color: '#FFFFFF'}}>About</a></p>
              <p>Portfolio</p>
              <p><a href='/product' style={{textDecoration: 'none', color: '#FFFFFF'}}>Product</a></p>
            </div>
          </Col>

          <Col lg={2} md={6} sm={12}>
            <div className='footer_content' style={{fontFamily: 'DM-Sans-Regular'}}>
              <h3>Our Services</h3>
              <p><a href='/' style={{textDecoration: 'none', color: '#FFFFFF'}}>Development</a></p>
              <p>Designing</p>
              <p><a href='/cloud-application-service' style={{textDecoration: 'none', color: '#FFFFFF'}}>Cloud Service</a></p>
              <p>Marketing</p>
            </div>
          </Col>

          <Col lg={2} md={6} sm={12}>
            <div className='footer_content' style={{fontFamily: 'DM-Sans-Regular'}}>
              <h3>Quick links</h3>
              <p>Blogs</p>
              <p><a href='/career' style={{textDecoration: 'none', color: '#FFFFFF'}}>Careers</a></p>
              <p>Policies</p>
              <p><a href='/contact' style={{textDecoration: 'none', color: '#FFFFFF'}}>Contact</a></p>
            </div>
          </Col>

          <Col lg={2} md={6} sm={12}>
            <div className='footer_content' style={{fontFamily: 'DM-Sans-Regular'}}>
              <h3>Join Our Team</h3>
              <a href='mailto:hr@praathee.com' style={{textDecoration: 'none', color: '#FFFFFF'}}><p>hr@praathee.com</p></a>
              <h3>Sales</h3>
              <a href='mailto:sales@praathee.com' style={{textDecoration: 'none', color: '#FFFFFF'}}><p>sales@praathee.com</p></a>
              <a href='tel:9043204157' style={{textDecoration: 'none', color: '#FFFFFF'}}><p>+91 9043204157</p></a>
            </div>
          </Col>
        </Row>
        <hr style={{paddingBottom: '24px'}}/>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
          <p style={{fontSize: '14px', fontWeight: 400, fontFamily: 'DM-Sans-Regular'}}>Copyrights <img src={copyrights_image} alt="" /> 2024 Praathee Technologies Private Limited</p>
          </Col>

          <Col lg={6} md={12} sm={12} xs={12} className='footer_end_content' style={{display: 'flex', justifyContent: 'end', marginBottom: '20px'}}>
          <p style={{fontSize: '14px', fontWeight: 400, fontFamily: 'DM-Sans-Regular'}}>All rights reserved</p>
          </Col>
        </Row>
      </Container>
    </Container>
    </>
  )
}

export default Footer