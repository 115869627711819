import React from 'react'
import It_Banner from './It_Banner/It_Banner'
import NavBar from '../../../common/NavBar/NavBar'
import CompetitiveStaffing from './Competitive_Staffing/Competitive_Staffing'
import Recruitment from './Recruitment/Recruitment'
import Footer from '../../../common/Footer/Footer'

const It_Staffing = () => {
  return (
    <>
    <NavBar/>
    <It_Banner/>
    <CompetitiveStaffing/>
    <Recruitment/>
    <Footer/>
    
    </>
  )
}

export default It_Staffing