import React from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import AndroidServiceImage from '../../../../assests/Services/Mobile_Application/AndroidServicesImage.svg'
import uiuxFloating from '../../../../assests/Services/Mobile_Application/uiuxFloatingImage.svg'
import testingFloatingImage from '../../../../assests/Services/Mobile_Application/testingFloatingImage.svg'
import mobileAppFloatingImage from '../../../../assests/Services/Mobile_Application/mobileAppFloatingImage.svg'
import developersFloatingImage from '../../../../assests/Services/Mobile_Application/developersFloatingImage.svg'
import {motion} from 'framer-motion'
import './androidservices.css'

const AndroidServices = () => {
  return (
    <>
    <Container>
      <Row>
        <Col lg={12}>
        <h1 style={{textAlign: 'center', marginBottom: '20px', fontFamily: 'Circular', fontSize: '24px'}}>Android App Development Services</h1>
        <p style={{fontFamily: 'Outfit-Regular', fontSize: '20px'}}>With mobile phones being popular, Android is one of the leading models that is been in use among many. At Praathee Technologies, we understand the use of Android well. So, keeping this in mind, we have businesses create, maintain, and revamp custom-based apps with tools like Java and Jetpack compose, etc. Also, we are well-equipped with customer-friendly professionals such as, 
        </p>
        </Col>

        <Col lg={12} style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{textAlign: 'center', width: '100%', position: 'relative'}}>    
            <img src={AndroidServiceImage} style={{width: '40%'}}/>
            <motion.img className='android_floating_image1' src={uiuxFloating} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{position: 'absolute', left: '62%', top: '5%'}}/>
            <motion.img className='android_floating_image2' src={testingFloatingImage} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{position: 'absolute', left: '64%', top: '54%'}}/>
            <motion.img className='android_floating_image3' src={mobileAppFloatingImage} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{position: 'absolute', left: '10%', top: '70%'}}/>
            <motion.img className='android_floating_image4' src={developersFloatingImage} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{position: 'absolute', left: '24%', top: '23%'}}/>
           
        </div>
        </Col>


        <p style={{fontFamily: 'Outfit-Regular', fontSize: '22px'}}>Hence, get your customer-friendly and innovative Android mobile applications made and attain high profitability effortlessly. 
        </p>
        </Row>
    </Container>
    </>
  )
}

export default AndroidServices