import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutBanner from '../../../assests/About/about_banner.svg'
import './aboutBanner.css'

const AboutBanner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
    <Container>
        <Row>
            <Col lg={6} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <h1 style={{fontWeight: 700, fontSize: '46px', marginBottom: '30px', fontFamily: 'Circular'}}>About Us</h1>
                <p style={{fontWeight: 400, fontSize: '22px', lineHeight: '45px', fontFamily: 'Outfit-Regular'}}>Praathee Technologies has been delivering exceptional software solutions for years, empowering businesses of all sizes with efficient, high-Our expert team crafts innovative, tailored solutions aligned with evolving technologies and market demands.</p>
            </Col>

            <Col lg={6} className='about_banner'>
                <img style={{width: '100%'}} src={aboutBanner} alt="" />
            </Col>
        </Row>
    </Container>
    </Container>
    </>
  )
}

export default AboutBanner