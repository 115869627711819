import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './HomeBanner.css'
import home_banner from '../../../assests/Home/home_banner_image.svg'
import dot_1 from '../../../assests/Home/dot_1.svg'
import {motion} from "framer-motion"
import ctrl_floating from '../../../assests/Home/Ctrl_floating.svg'
import smiling_message from '../../../assests/Home/Smiling_message.svg'
import smiling_usb from '../../../assests/Home/Smiling_usb.svg'
import top_left_spring from '../../../assests/Home/top_left_spring.svg'
import top_right_spring from '../../../assests/Home/top_right_spring.svg'
import floating_mobile from '../../../assests/Home/floating_mobile.svg'
import happy_cloud from '../../../assests/Home/happy_cloud.svg'
import right_star_1 from '../../../assests/Home/right_star_1.svg'
import right_star_2 from '../../../assests/Home/right_star_2.svg'
import wifi from '../../../assests/Home/wifi.svg'
import chain from '../../../assests/Home/chain.svg'
import left_star from '../../../assests/Home/left_star_1.svg'
import bottom_right_spring from '../../../assests/Home/bottom_right_spring.svg'
import dot_3 from '../../../assests/Home/dot_3.svg'
import dot_4 from '../../../assests/Home/dot_4.svg'
import bottom_star_1 from '../../../assests/Home/bottom_star_1.svg'
import bottom_star_2 from '../../../assests/Home/bottom_star_2.svg'
import bottom_star_3 from '../../../assests/Home/bottom_star_3.svg'
import left_bottom_dot from '../../../assests/Home/left_bottom_dot.svg'
import left_bottom_dot_2 from '../../../assests/Home/left_bottom_dot_2.svg'
import smiling_at from '../../../assests/Home/smiling_at.svg'
import left_bottom_spring from '../../../assests/Home/left_bottom_spring.svg'

const HomeBanner = () => {


  const texts = [
    "Mobile Apps",
    "Web Apps",
    "ERP",
    "Blockchain",
    "SEO"
  ];

  const [currentIndex, setCurrentIndex] = useState(0)


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, 2000)
    

    return () => clearInterval(intervalId)
  }, [texts.length])


  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
        <Container style={{paddingTop: '30px'}}>
        <Row>
            <Col lg={6} className='home_floating_image' style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
            {/* {currentIndex === 0 && <img src={home_banner} style={{height: '77%'}} alt="" />} */}
            <img src={home_banner} style={{height: '77%'}} alt="" />
            <motion.img animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} src={dot_1} style={{width: '12.93px', position: 'absolute', left: '23%', top: '7%'}} alt="" />
            <motion.img src={top_left_spring} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '36.05px', position: 'absolute', left: '33%', top: '14%'}}/>
            <motion.img src={ctrl_floating} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '67.13px', position: 'absolute', left: '31%'}}/>
            <motion.img src={smiling_message} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '67.13px', position: 'absolute', left: '47%', top: '-3%'}}/>
            <motion.img src={smiling_usb} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '65.48px', position: 'absolute', left: '60%'}}/>
            <motion.img src={top_right_spring} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '36.1px', position: 'absolute', left: '74%'}}/>
            <motion.img src={floating_mobile} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '126px', position: 'absolute', left: '73%', top: '8%'}}/>
            <motion.img src={happy_cloud} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '74px', position: 'absolute', left: '77%', top: '38%'}}/>
            <motion.img src={right_star_1} animate={{scale: [0.5, 1.5, 0.5]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '8.64px', position: 'absolute', left: '75%', top: '45%'}}/>
            <motion.img src={right_star_2} animate={{scale: [0.5, 1.5, 0.5]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '20.4px', position: 'absolute', left: '74%', top: '51%'}}/>
            <motion.img src={wifi} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '54.2px', position: 'absolute', left: '12%', top: '13%'}}/>
            <motion.img src={chain} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '58.63px', position: 'absolute', left: '21%', top: '24%'}}/>
            <motion.img src={left_star} animate={{scale: [0.5, 1.5, 0.5]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '18.53px', position: 'absolute', left: '35%', top: '24%'}}/>
            <motion.img src={bottom_right_spring} animate={{ x: [-10, 10, -10], y: [-10, 10, -10] }} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '29.27px', position: 'absolute', left: '76%', top: '57%'}}/>
            <motion.img src={dot_3} animate={{y: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '8.77px', position: 'absolute', left: '85%', top: '54%'}}/>
            <motion.img src={dot_4} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '19px', position: 'absolute', left: '71%', top: '70%'}}/>
            <motion.img src={bottom_star_1} animate={{scale: [0.5, 1.5, 0.5]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '13.39px', position: 'absolute', left: '63%', top: '65%'}}/>
            <motion.img src={bottom_star_2} animate={{scale: [0.5, 1.5, 0.5]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '8.64px', position: 'absolute', left: '48%', top: '72%'}}/>
            <motion.img src={bottom_star_3} animate={{scale: [0.5, 1.5, 0.5]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '12.94px', position: 'absolute', left: '39%', top: '70%'}}/>
            <motion.img src={left_bottom_spring} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '40px', position: 'absolute', left: '20%', top: '55%'}}/>
            <motion.img src={left_bottom_dot} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '19px', position: 'absolute', left: '12%', top: '52%'}}/>
            <motion.img src={smiling_at} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '52.87px', position: 'absolute', left: '8%', top: '34%'}}/>
            <motion.img src={left_bottom_dot_2} animate={{x: [-10, 10, -10]}} transition={{duration: 4, repeat: Infinity, ease: "easeInOut"}} style={{width: '8.73px', position: 'absolute', left: '10%', top: '30%'}}/>
            </Col>


            <Col lg={6} md={12} style={{fontFamily: 'Circular'}}>
            <div className='home_banner_heading'>
                <h2 style={{fontSize: '46px', fontFamily: 'Circular', fontWeight: 700}}>Empowering Your Business Online With </h2>
                <h2 style={{fontFamily: 'Circular'}} className='home_changing_text'>{texts[currentIndex]}</h2>
            </div>

            <p className='home_banner_content' style={{fontFamily: 'Circular-Book', lineHeight: '36px'}}>"Unlock your business's full potential. Contact us today—our experts are just a call or email away!"</p>
            <Button className='rounded-pill home_banner_button'>Explore now</Button>
            </Col>
        </Row>
        </Container>
    </Container>
    </>
  )
}

export default HomeBanner