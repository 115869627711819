import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import homeUserTieIcon from '../../../assests/Home/home_overview_user_tie.svg'
import homeSuccessfulProjectsIcon from '../../../assests/Home/home_overview_successfull_projects.svg'
import homeClientRatingsIcon from '../../../assests/Home/home_overview_client_rating.svg'
import homeSupportIcon from '../../../assests/Home/home_overview_support.svg'
import './Overview.css'

const Overview = () => {
  return (
    <>
    <Container fluid style={{boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)", paddingTop: '40px', paddingBottom: '40px'}}>
        <Container>
            <Row>
                <Col lg={3} md={6} sm={6}>
                  <div className='overview'>
                    <img src={homeUserTieIcon} alt="" />
                    <p style={{fontFamily: 'Circular'}}>7+</p>
                  </div>
                  <p style={{textAlign: 'center', fontWeight: 400, fontSize: '20px', fontFamily: 'Outfit-Regular'}}>Years of trusted solutions</p>
                </Col>


                <Col lg={3} md={6} sm={6}>
                  <div className='overview' >
                  <img src={homeSuccessfulProjectsIcon} alt="" />
                    <p style={{fontFamily: 'Circular'}}>100+</p>
                  </div>
                  <p style={{textAlign: 'center', fontWeight: 400, fontSize: '20px', fontFamily: 'Outfit-Regular'}}>Successful Projects</p>
                </Col>


                <Col lg={3} md={6} sm={6}>
                  <div className='overview' >
                  <img src={homeClientRatingsIcon} alt="" />
                    <p style={{fontFamily: 'Circular'}}>98%</p>
                  </div>
                  <p style={{textAlign: 'center', fontWeight: 400, fontSize: '20px', fontFamily: 'Outfit-Regular'}}>Client Rating</p>
                </Col>


                <Col lg={3} md={6} sm={6}>
                  <div className='overview'>
                  <img src={homeSupportIcon} alt="" />
                    <p style={{fontFamily: 'Circular'}}>24/7</p>
                  </div>
                  <p style={{textAlign: 'center', fontWeight: 400, fontSize: '20px', fontFamily: 'Outfit-Regular'}}>Support</p>
                </Col>
            
            </Row>
        </Container>
    </Container>
    </>
  )
}

export default Overview