import React from 'react'
import BlockchainBanner from './Blockchain_Banner/BlockchainBanner'
import NavBar from '../../../common/NavBar/NavBar'
import BlockchainServices from './Blockchain_Services/BlockchainServices'
import Footer from '../../../common/Footer/Footer'

const Blockchain = () => {
  return (
    <>
    <NavBar/>
    <BlockchainBanner/>
    <BlockchainServices/>
    <Footer/>
    </>
  )
}

export default Blockchain