import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import softwareBanner from '../../../../assests/Services/Software_Maintenance/softwareBanner.svg'
import './softwareBanner.css'

const Software_Banner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)', paddingTop: '20px'}}>
        <Container>
            <Row>
                <Col lg={6} className='softwareBanner'>
                    <img src={softwareBanner} style={{width: '80%'}}/>
                </Col>

                <Col lg={6} md={12} sm={12} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <h1 style={{fontSize: '28px', fontFamily: 'Circular', paddingBottom: '20px'}}>Software maintenance & support</h1>
                    <p style={{fontSize: '20px', lineHeight: '40px', fontFamily: 'Outfit-Regular'}}>At Praathee, we provide one of the most effective services that not only concentrates on creating or building successful applications. Instead, everything is given higher priority from the start to the end. For instance, with us, you will get the most impeccable software support and maintenance services, resulting in many important aspects such as bug-free applications and enhanced software security.</p>
                </Col>
            </Row>
        </Container>
    </Container>
    </>
  )
}

export default Software_Banner