import React, { useEffect } from 'react'
import CareerBanner from './CareerBanner/CareerBanner'
import NavBar from '../../common/NavBar/NavBar'
import BenefitsOfJoining from './BenefitsOfJoining/BenefitsOfJoining'
import Kickstart from './Kickstart/Kickstart'
import Footer from '../../common/Footer/Footer'
import Kickstart2 from './Kickstart2/Kickstart2'

const CareerPage = () => {

  useEffect(() => {
    document.title = "Career"
  }, [])

  return (
    <>
    <NavBar/>
    <CareerBanner/>
    <BenefitsOfJoining/>
    <Kickstart2/>
    <Kickstart/>
    <Footer/>
    </>
  )
}

export default CareerPage