import React from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import ItBanner from '../../../../assests/Services/IT_Staffing/ItBanner.svg'
import './ItBanner.css'

const It_Banner = () => {
  return (
    <>
    <Container fluid style={{background: 'linear-gradient(90deg, #E9F0FF, #EBFBFF, #FEF6FF, #EFEFFF)'}}>
        <Container>
            <Row>
                <Col lg={6} className='It_Banner'>
                    <img src={ItBanner} style={{width: '90%'}}/>
                </Col>

                <Col lg={6} md={12} sm={12} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <h1 style={{fontSize: '28px', fontFamily: 'Circular', paddingBottom: '22px'}}>IT Staffing & Support</h1>
                    <p style={{fontSize: '20px', fontFamily: 'Outfit-Regular', lineHeight: '40px'}}>With Praathee's exceptional organization resources and professional experts, get the best IT staffing services and find the perfect fit for your organization's needs seamlessly.</p>
                </Col>
            </Row>
        </Container>

    </Container>
    </>
  )
}

export default It_Banner